// /api/v1 / dictionary / city / all
import { api } from "../common";
import {
  API_CITIES_DICTIONARY,
  API_LABEL_ADD,
  API_LABELS_BY_PART_TEXT,
  API_MANAGERS_DICTIONARY,
} from "../constants";
import { MultiselectData } from "@aurora/components";

export const baseApi = api.injectEndpoints({
  endpoints: builder => ({
    addNewLabel: builder.mutation<any, MultiselectData>({
      query: (data: MultiselectData) => {
        return {
          data,
          method: "POST",
          url: API_LABEL_ADD,
          withCredentials: true,
        };
      },
    }),
    cities: builder.query<any, void>({
      query: () => {
        return {
          method: "GET",
          url: `${API_CITIES_DICTIONARY}`,
          withCredentials: true,
        };
      },
    }),
    labelsByPartText: builder.query<any, any>({
      query: params => {
        return {
          method: "GET",
          params: {
            partOfValue: params,
          },
          url: API_LABELS_BY_PART_TEXT,
          withCredentials: true,
        };
      },
    }),
    managers: builder.query<any, void>({
      query: () => {
        return {
          method: "GET",
          url: `${API_MANAGERS_DICTIONARY}`,
          withCredentials: true,
        };
      },
    }),
  }),
});

export const {
  useAddNewLabelMutation,
  useCitiesQuery,
  useLabelsByPartTextQuery,
  useManagersQuery,
} = baseApi;
