import { ROUTE_CREATE_CANDIDATE } from "../../../constants/routes";
import { useCandidatesListQuery } from "../../../core/api/candidate/candidate";
import { generateRandomIdByLength } from "../../../services/generate.service";
import { camelCaseToUnderscore } from "../../../services/string.service";
import CreateCandidateModal from "../../BaseComponents/Popups/CreateCandidate/Modal";
import MassEffectModal from "../../BaseComponents/Popups/MassEfffect/Modal";
import style from "./Candidates.module.scss";
import FilterSideBar from "./FilterSideBar/FilterSideBar";
import CandidatesList from "./List/CandidatesList";
import {
  QueryParameters,
  SortDirectionField,
  SortDirectionUS,
  SortMapping,
} from "./types";
import { Pagination } from "@aurora/components";
import { GlyphBetweenAccounts, GlyphPlus, GlyphSettings } from "@aurora/icons";
import cx from "classnames";
import AdditionallyMenu from "components/BaseComponents/AdditionallyMenu";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const sortMapping: SortMapping = {
  fullName: "FULL_NAME",
  resumeUploadDate: "RESUME_UPLOAD_DATE",
};

const Candidates: FC = () => {
  const isManagerOrWatcher = useSelector(
    (store: any) => store.userDataSlice.isManagerOrWatcher,
  );
  const [queryParams, setQueryParams] = useState<QueryParameters>({
    pageNum: 1,
    pageSize: 10,
    rnd: "",
  });
  const navigate = useNavigate();

  const [isOpenAddCandidateModal, changeIsOpenAddCandidateModal]: any =
    useState(false);
  const [isOpenMassEffectModal, changeMassEffectModal]: any = useState(false);

  const { data: candidateData, isLoading: isCandidatesLoading } =
    useCandidatesListQuery(queryParams, { refetchOnMountOrArgChange: true });

  const pageCount = candidateData
    ? Math.ceil(candidateData?.total / queryParams.pageSize)
    : 0;

  return (
    <>
      <div className={cx(style.CandidatesHeader)}>
        <h1 className={cx("my-0 text-black")}>Кандидаты</h1>
        {!isManagerOrWatcher && (
          <div>
            <AdditionallyMenu
              btn={{
                className: cx(style.CreateButton, "px-12"),
                icon: <GlyphPlus />,
                name: "Добавить кандидата",
                shape: "circle",
                variant: "primary",
              }}
              list={[
                {
                  icon: <GlyphBetweenAccounts />,
                  name: "Загрузить резюме",
                  onClick: () => {
                    changeIsOpenAddCandidateModal(true);
                  },
                },
                {
                  icon: <GlyphSettings />,
                  name: "Вручную",
                  onClick: () => {
                    navigate(ROUTE_CREATE_CANDIDATE);
                  },
                },
              ]}
            />
          </div>
        )}
      </div>

      <div className={cx(style.CandidatesList)}>
        <div className={style.CandidatesListLeftSide}>
          <CandidatesList
            candidatesData={candidateData}
            isCandidatesLoading={isCandidatesLoading}
            showStatuses={false}
            updateCandidateData={(sortParams?: SortDirectionField) => {
              let newQueryParams: QueryParameters = {
                ...queryParams,
                rnd: generateRandomIdByLength(10),
              };
              if (sortParams) {
                const sortFieldValue = Object.keys(sortParams).find(
                  v => sortParams[v as keyof SortDirectionField] !== undefined,
                );
                const sortFieldValueUS =
                  sortMapping[sortFieldValue as keyof SortMapping];
                const sortOrderValue = camelCaseToUnderscore(
                  sortParams[sortFieldValue as keyof SortDirectionField],
                ) as SortDirectionUS;
                newQueryParams = {
                  ...queryParams,
                  sortField: sortFieldValueUS,
                  sortOrder: sortOrderValue,
                };
              }
              setQueryParams(newQueryParams);
            }}
          />
          {pageCount > 1 ? (
            <Pagination
              className="pl-0 pagination pb-0"
              currentNumber={queryParams.pageNum}
              onChange={function ka(page: any) {
                setQueryParams({ ...queryParams, pageNum: page });
              }}
              total={pageCount}
            />
          ) : (
            ""
          )}
        </div>
        <div className={style.CandidatesListRightSide}>
          <FilterSideBar setQueryParams={setQueryParams} />
        </div>
      </div>
      {isOpenAddCandidateModal ? (
        <CreateCandidateModal
          clickOnClose={() => {
            changeIsOpenAddCandidateModal(false);
          }}
          onSuccessModal={() => {
            setQueryParams({
              ...queryParams,
              rnd: generateRandomIdByLength(10),
            });
          }}
        />
      ) : null}
      {isOpenMassEffectModal && (
        <MassEffectModal
          clickOnClose={() => {
            changeMassEffectModal(false);
          }}
        />
      )}
    </>
  );
};

export default Candidates;
