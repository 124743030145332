import { reportTypes } from "../constants";
import {
  Button,
  Select,
  Multiselect,
  MultiselectData,
  InputDatepicker,
} from "@aurora/components";
import { GlyphSearch } from "@aurora/icons";
import FilterListLoader from "components/BaseComponents/FilterListLoader";
import { useCitiesQuery } from "core/api/base/other";
import {
  useVacationAutocompleteManagerQuery,
  useVacationAutocompleteTitleQuery,
  useVacationCategoriesQuery,
  useVacationStatusesQuery,
} from "core/api/vacancy/vacancy";
import { FC, useEffect, useState } from "react";
import { getDateFormattedFromDate } from "services/date.service";

interface State {
  onGetReport: any;
  changeTypeReport: any;
  isLoading: boolean;
}

const Filters: FC<State> = ({ changeTypeReport, isLoading, onGetReport }) => {
  const [filterTypeValues] = useState([
    { id: reportTypes.VACANCY, value: "Отчет по вакансиям" },
    { id: reportTypes.CATEGORY, value: "Статистика по категориям" },
  ]);

  const [type, changeType]: any = useState(null);

  const [autocompleteTitle, changeAutocompleteTitle] = useState("");
  const [autocompleteManager, changeAutocompleteManager] = useState("");
  const [, changeAutocompleteCities] = useState("");

  const [vacancyOption, setVacancyOption] = useState<MultiselectData[]>([]);
  const [categoryOption, setCategoryOption] = useState<MultiselectData[]>([]);
  const [managerOption, setManagerOption] = useState<MultiselectData[]>([]);
  const [locationOption, setLocationOption] = useState<MultiselectData[]>([]);
  const [statusOption, setStatusOption] = useState<MultiselectData[]>([]);
  const [periodOption, setPeriodOption]: any = useState([]);

  const {
    data: AutocompleteManagerData,
    error: ManagerAutocompleteError,
    isLoading: managerAutocompleteLoader,
  } = useVacationAutocompleteManagerQuery(autocompleteManager, {
    skip: autocompleteManager.length < 3,
  });

  const {
    data: AutocompleteTitleData,
    error: TitleAutocompleteError,
    isLoading: titleAutocompleteLoader,
  } = useVacationAutocompleteTitleQuery(autocompleteTitle, {
    skip: autocompleteTitle.length < 3,
  });

  const {
    data: CategoriesData,
    error: CategoriesError,
    isLoading: isCategoriesLoading,
  } = useVacationCategoriesQuery();

  const {
    data: StatueseData,
    error: StatueseError,
    isLoading: isStatueseLoading,
  } = useVacationStatusesQuery();

  const {
    data: CitiesData,
    error: CitiesError,
    isLoading: isCitiesLoading,
  } = useCitiesQuery();

  useEffect(() => {
    if (ManagerAutocompleteError)
      console.error("ManagerAutocompleteError", ManagerAutocompleteError);
    if (TitleAutocompleteError)
      console.error("TitleAutocompleteError", TitleAutocompleteError);
    if (CategoriesError) console.error("CategoriesError", CategoriesError);
    if (StatueseError) console.error("StatueseError", StatueseError);
    if (CitiesError) console.error("CitiesError", CitiesError);
  }, [
    CitiesError,
    StatueseError,
    CategoriesError,
    TitleAutocompleteError,
    ManagerAutocompleteError,
  ]);

  const handleReset = function () {
    setVacancyOption([]);
    setCategoryOption([]);
    setManagerOption([]);
    setLocationOption([]);
    setStatusOption([]);
    setPeriodOption([]);
  };

  const handleSubmit = function () {
    onGetReport({
      categoryIds: categoryOption.map(cat => cat.id),
      createPeriodEnd: getDateFormattedFromDate(periodOption[1]),
      createPeriodStart: getDateFormattedFromDate(periodOption[0]),
      locations: locationOption.map(loc => loc.value),
      managerIds: managerOption.map(manager => manager.id),
      statusIds: statusOption.map(status => status.id),
      vacancyTitles: vacancyOption.map(vacancy => vacancy.value),
    });
  };

  return (
    <>
      {isCitiesLoading || isStatueseLoading || isCategoriesLoading ? (
        <FilterListLoader />
      ) : (
        <div>
          <div>
            <Select
              data={filterTypeValues}
              indent={false}
              onChange={(type: any) => {
                changeType(type);
                changeTypeReport(type.id);
              }}
              placeholder="Выберите тип"
              shape="select"
              value={type}
            />

            {type && (
              <>
                <InputDatepicker
                  className="my-8"
                  classNameBackgroundColor={undefined}
                  dates={periodOption}
                  disabled={false}
                  onBlurContainerField={undefined}
                  onFocusContainerField={undefined}
                  onSubmit={setPeriodOption}
                  placeholder={"Период"}
                />

                <Multiselect
                  className="mb-8"
                  data={CategoriesData}
                  indent={false}
                  onChange={setCategoryOption}
                  placeholder="Категория"
                  value={categoryOption}
                />

                {type && type.id === "vacancy" && (
                  <>
                    <Multiselect
                      addonBefore={<GlyphSearch />}
                      className="mb-8"
                      data={AutocompleteTitleData || []}
                      indent={false}
                      loading={titleAutocompleteLoader}
                      onChange={setVacancyOption}
                      onInputChange={changeAutocompleteTitle}
                      placeholder="Название вакансии"
                      shape="select"
                      showArrow={false}
                      value={vacancyOption}
                    />
                    <Multiselect
                      addonBefore={<GlyphSearch />}
                      className="mb-8"
                      data={AutocompleteManagerData || []}
                      indent={false}
                      loading={managerAutocompleteLoader}
                      onChange={setManagerOption}
                      onInputChange={(value: string) => {
                        changeAutocompleteManager(value);
                      }}
                      placeholder="Имя менеджера"
                      shape="select"
                      showArrow={false}
                      value={managerOption}
                    />
                    <Multiselect
                      addonBefore={<GlyphSearch />}
                      className="mb-8"
                      data={CitiesData || []}
                      indent={false}
                      loading={isCitiesLoading}
                      onChange={setLocationOption}
                      onInputChange={(value: string) => {
                        changeAutocompleteCities(value);
                      }}
                      placeholder="Локация"
                      shape="select"
                      showArrow={false}
                      value={locationOption}
                    />

                    <Multiselect
                      className="mb-8"
                      data={StatueseData}
                      indent={false}
                      onChange={setStatusOption}
                      placeholder="Статус"
                      value={statusOption}
                    />
                  </>
                )}
              </>
            )}
          </div>

          <div>
            {type && (
              <>
                <Button
                  className="mb-4"
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  Сформировать отчет
                </Button>
                <Button onClick={handleReset} variant="secondary">
                  Сбросить
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default Filters;
