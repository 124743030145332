import { store } from "../../../core/store";
import { setVisible } from "../../../core/store/reducers/ContextMenuSlice";
import style from "./ContextMenu.module.scss";
import { ActionCell, CardCell } from "@aurora/components";
import cx from "classnames";
import React, { useState, useRef, useEffect, FC } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";

interface IDataItem {
  onClick?: any;
  name?: string;
  icon?: any;
  variant?: string;
  className?: string;
  hide?: boolean;
  shape?: string;
}
interface IContextMenuProps {
  list: IDataItem[];
}
const ContextMenu: FC<IContextMenuProps> = ({ list }) => {
  const { visible, x, y } = useSelector((state: any) => state.contextMenuSlice);
  const [timeoutId, setTimeoutId]: any = useState(null);

  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [visible]);

  useEffect(() => {
    if (ref.current) {
      const menuHeight = ref.current.offsetHeight;
      const menuWidth = ref.current.offsetWidth;
      const div = document.getElementById("contentMainContainer")!;
      const contentMain = document.getElementById("contentMain")!;
      const contentMainRect = contentMain.getBoundingClientRect();
      const divRect = div.getBoundingClientRect();
      let top = y + contentMain.scrollTop + menuHeight - 40;
      const left = x - divRect.x - menuWidth;

      // Проверка, выходит ли меню за границы окна и корректировка позиции
      if (top + menuHeight > contentMainRect.height) {
        top -= menuHeight;
      }
      // if (x + menuWidth > windowWidth) {
      //   store.dispatch(setPositionX(x - menuWidth));
      // }

      ref.current.style.top = `${top}px`;
      ref.current.style.left = `${left}px`;
    }
  }, [x, y, visible]);

  const handleClickOutside = (event: any) => {
    if (visible && !event.target.closest("#additionalContextMenu")) {
      store.dispatch(setVisible(false));
    }
  };

  const hideMenu = () => {
    store.dispatch(setVisible(false));
  };

  const handleMouseLeave = () => {
    setTimeoutId(setTimeout(hideMenu, 500));
  };
  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
  };

  if (!visible) return null;

  return ReactDOM.createPortal(
    <>
      <div
        ref={ref}
        className={cx(style.ContextMenu)}
        id="additionalContextMenu"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          left: `${x}px`,
          top: `${y}px`,
        }}
      >
        <CardCell indent={false}>
          <div className={cx(style.ContextMenuList)}>
            {list.map((dataItem: any, idx: number) => {
              return dataItem.hide ? null : (
                <div key={idx}>
                  <ActionCell
                    className={cx(
                      "cursor-pointer",
                      dataItem.className,
                      "px-12",
                      "py-6",
                    )}
                    iconLeft={dataItem.icon}
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      dataItem.onClick();
                    }}
                    separator={idx < list?.length - 1}
                    title={dataItem.name}
                  />
                </div>
              );
            })}
          </div>
        </CardCell>
      </div>
    </>,
    document
      .getElementById("contentMainContainer")!
      .getElementsByTagName("*")[0],
  );
};

export default ContextMenu;
