const monthsInRussian = [
  "Января",
  "Февраля",
  "Марта",
  "Апреля",
  "Мая",
  "Июня",
  "Июля",
  "Августа",
  "Сентября",
  "Октября",
  "Ноября",
  "Декабря",
];

export function formatDateUTC(date: Date | null): string {
  if (!date) return "";
  // Преобразуем дату в UTC
  const utcDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000,
  );

  return utcDate.toJSON().slice(0, 10);
}

export function getDateFormattedFromDate(date: Date | null) {
  if (!date) return "";

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Добавляем 1, так как месяцы в JavaScript начинаются с 0
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function dateToRu(date: string | null): string {
  if (!date) return "";
  const instanceOfDateFromString = new Date(date);

  // // Получаем необходимые компоненты даты
  const day = instanceOfDateFromString.getDate().toString().padStart(2, "0");
  const month = (instanceOfDateFromString.getMonth() + 1)
    .toString()
    .padStart(2, "0");
  const year = instanceOfDateFromString.getFullYear();

  // Конвертируем дату в строку в формате "dd.mm.yyyy"
  return `${day}.${month}.${year}`;
}

export function convertUtcToLocal(utcDate: any) {
  const offset = new Date().getTimezoneOffset(); // Получаем разницу в минутах между UTC и локальным временем
  const localTimeInMilliseconds = utcDate.getTime() - offset * 60 * 1000; // Применяем разницу к UTC времени
  const localTime = new Date(localTimeInMilliseconds);

  return localTime.toLocaleString().split(" ")[1];
}

export function formatISODate(date: Date | null): string {
  if (!date) return "";

  return date.toLocaleDateString("en-CA");
}

export function differenceOfDays(date_1: Date, date_2: Date) {
  const difference = date_1.getTime() - date_2.getTime();
  const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
}

export function getCurrentMonthAndDay(date_1: Date) {
  return `${date_1.getDate()} ${monthsInRussian[date_1.getMonth()]}`;
}
