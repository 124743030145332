import { OAuth2Token, OAuth2TokenResponse } from "../../types/oauth2";
import { api } from "./common";
import { API_AUTH_LOGIN, API_AUTH_LOGOUT, API_AUTH_REFRESH } from "./constants";

export const oauth2Api = api.injectEndpoints({
  endpoints: builder => ({
    postLogin: builder.mutation<OAuth2TokenResponse, OAuth2Token>({
      query: ({ password, username }) => ({
        data: {
          password,
          username,
        },
        method: "POST",
        url: `${API_AUTH_LOGIN}`,
        withCredentials: true,
      }),
    }),
    postLogout: builder.mutation({
      query: () => ({
        method: "POST",
        url: `${API_AUTH_LOGOUT}`,
      }),
    }),
    refresh: builder.mutation<any, any>({
      query: () => ({
        method: "GET",
        url: `${API_AUTH_REFRESH}`,
      }),
    }),
  }),
});

export const {
  usePostLoginMutation,
  usePostLogoutMutation,
  useRefreshMutation,
} = oauth2Api;
