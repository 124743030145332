import { api } from "../common";
import { API_AUTH_LOGOUT } from "../constants";
import { OAuth2TokenResponse } from "types/vacation";

export const logoutApi = api.injectEndpoints({
  endpoints: builder => ({
    logout: builder.query<OAuth2TokenResponse, void>({
      query: () => ({
        method: "POST",
        url: `${API_AUTH_LOGOUT}`,
        withCredentials: true,
      }),
    }),
  }),
});

export const { useLogoutQuery } = logoutApi;
