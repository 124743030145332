import style from "./Modal.module.scss";
import { Button, Popup, useSnackbars } from "@aurora/components";
import cx from "classnames";
import { DICTIONARY } from "constants/dictionary";
import { usePersonUnbindFromVacancyMutation } from "core/api/candidate/candidate";
import React, { FC } from "react";
import { snackbarFail, snackbarSuccess } from "services/snackbar.service";

interface Props {
  clickOnClose: any;
  candidateId: any;
  vacancy: any;
}

const DeleteCandidateFromVacancy: FC<Props> = ({
  candidateId,
  clickOnClose,
  vacancy,
}) => {
  const { snackbarInfo } = useSnackbars();
  const [unbindPersonFromVacancy] = usePersonUnbindFromVacancyMutation();

  return (
    <>
      <Popup
        classNameContent={cx(style.modal, "p-16")}
        classNameWrapper="p-0"
        onClickCloseCross={clickOnClose}
        onClickOutside={clickOnClose}
      >
        <>
          <div className={"title-and-buttons-align"}>
            <h1 className={cx("my-0")}>Отвязать кандидата?</h1>
          </div>
          <div>
            <p className="my-12">
              Вы уверены, что хотите отвязать карточку кандидата от вакансии? Вы
              сможете привязать её вновь
            </p>
            <div className={cx("d-flex")}>
              <Button
                className={cx("mr-4")}
                onClick={(e?: React.MouseEvent) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  clickOnClose();
                }}
                variant="secondary"
              >
                Отмена
              </Button>
              <Button
                onClick={(e?: React.MouseEvent) => {
                  e?.preventDefault();
                  e?.stopPropagation();

                  unbindPersonFromVacancy({
                    personId: candidateId,
                    vacancyId: vacancy.id,
                  }).then((result: any) => {
                    clickOnClose(true);
                    if (result.data) {
                      snackbarInfo?.show(
                        snackbarSuccess(
                          DICTIONARY.UNBIND_CANDIDATE_VACANCY(
                            result.data.fullName,
                            vacancy.title,
                          ),
                        ),
                      );
                    } else {
                      const fail: any = snackbarFail(DICTIONARY.FAIL);
                      snackbarInfo?.show(fail);
                    }
                  });
                }}
                variant="sell"
              >
                Отвязать
              </Button>
            </div>
          </div>
        </>
      </Popup>
    </>
  );
};
export default DeleteCandidateFromVacancy;
