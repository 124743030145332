import { store } from "../../../../core/store";
import { resetToInitState } from "../../../../core/store/reducers/FilterSelectedSlice";
import { camelCaseToUnderscore } from "../../../../services/string.service";
import {
  QueryParameters,
  SortDirectionUS,
  SortFieldUS,
  IVacanciesContext,
  SortDirectionField,
  SortField,
  SortMapping,
} from "../types";
import style from "./List.module.scss";
import { Button, Pagination, Skeleton } from "@aurora/components";
import { GlyphEdit, GlyphSwap, SmallFall, SmallGrow } from "@aurora/icons";
import cx from "classnames";
import VacationCard from "components/AuthComponents/VacationCard";
import NoData from "components/BaseComponents/NoData";
import ChangeStatusPopup from "components/BaseComponents/Popups/VacancyChangeStatus/Modal";
import RightSidebar from "components/BaseComponents/RightSidebar/RightSidebar";
import VacancyBadge from "components/BaseComponents/VacancyBadge";
import { ROUTE_VACANCIES_UPDATE } from "constants/routes";
import React, { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dateToRu } from "services/date.service";
import { generateRandomIdByLength } from "services/generate.service";

function declOfNum(n: number, text_forms: any) {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return text_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1];
  }
  if (n1 == 1) {
    return text_forms[0];
  }
  return text_forms[2];
}

function getCountOfDaysInWork(inWork: number, countInWork: number) {
  return {
    daysInWork:
      `${inWork} ${declOfNum(inWork, ["день", "дня", "дней"])}` || "-",
    isExpired: inWork > countInWork,
  };
}

const initSortDirection: SortDirectionField = {
  createDate: undefined,
  inProgressDays: undefined,
  location: undefined,
  managerFullName: undefined,
  statusTitle: undefined,
  title: undefined,
};
const sortMapping: SortMapping = {
  createDate: "CREATE_DATE",
  inProgressDays: "IN_PROGRESS_DAYS",
  location: "LOCATION",
  managerFullName: "MANAGER",
  statusTitle: "STATUS",
  title: "TITLE",
};

const List: FC<IVacanciesContext> = ({
  isVacanciesLoading,
  pageCount,
  queryParams,
  setQueryParams,
  vacanciesData,
}) => {
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const [isModalStatusEditOpen, setIsModalStatusEditOpen] = useState(false);
  const [sortDirection, setSortDirection] =
    useState<SortDirectionField>(initSortDirection);
  const [searchParams, setSearchParams] = useSearchParams();
  const isManagerOrWatcher = useSelector(
    (store: any) => store.userDataSlice.isManagerOrWatcher,
  );

  const [
    udpateCardCallbackAfterChangeStatus,
    setNewCallbackForUpdateCard,
  ]: any = useState(null);

  const navigate = useNavigate();

  const [selectedTableItem, changeSelectedTableItem]: any = useState({
    id: null,
  });

  useEffect(() => {
    if (!isVacanciesLoading) {
      let newQueryParams: QueryParameters = {
        ...queryParams,
        rnd: generateRandomIdByLength(10),
      };
      if (sortDirection) {
        const sortFieldValue = Object.keys(sortDirection).find(
          v => sortDirection[v as keyof SortDirectionField] !== undefined,
        );
        const sortFieldValueUS: SortFieldUS =
          sortMapping[sortFieldValue as keyof SortMapping];
        const sortOrderValue = camelCaseToUnderscore(
          sortDirection[sortFieldValue as keyof SortDirectionField],
        ) as SortDirectionUS;
        newQueryParams = {
          ...queryParams,
          sortField: sortFieldValueUS,
          sortOrder: sortOrderValue,
        };
      }
      setQueryParams(newQueryParams);
    }
  }, [sortDirection]);

  const header = [
    { element: <div>Наименование вакансии</div>, item: "title" },
    { element: <div>Нанимающий менеджер</div>, item: "managerFullName" },
    { element: <div>Дата создания</div>, item: "createDate" },
    { element: <div>Локация</div>, item: "location" },
    { element: <div>Кол-во дней в работе</div>, item: "inProgressDays" },
    { element: <div>Текущий статус</div>, item: "statusTitle" },
    { element: "", item: "actionBtn" },
  ];

  const tableHead = header.map((item: any) => {
    const sortIcon = (
      <div
        className={cx(
          style.VacationTable__icon,
          style.VacationTable__icon__align_left,
        )}
      >
        {sortDirection[item.item as SortField] === "asc" && (
          <SmallFall data-testid="sort-down" />
        )}
        {sortDirection[item.item as SortField] === "desc" && (
          <SmallGrow data-testid="sort-up" />
        )}
      </div>
    );
    function onSort(item: SortField) {
      if (!sortDirection[item])
        setSortDirection({ ...initSortDirection, [item]: "asc" });
      if (sortDirection[item] === "asc")
        setSortDirection({ ...initSortDirection, [item]: "desc" });
      if (sortDirection[item] === "desc")
        setSortDirection({ ...initSortDirection });
    }
    return (
      <th
        key={item.item}
        className={cx(item.item in sortDirection && style.VacationTable__point)}
        onClick={() => {
          if (item.item in sortDirection) onSort(item.item);
        }}
      >
        {item.item in sortDirection && sortIcon}
        {item.element}
      </th>
    );
  });
  const handleRightSidebarClose = () => {
    setIsRightSidebarOpen(false);
  };

  useEffect(() => {
    if (searchParams.get("vacancyId")) {
      changeSelectedTableItem({ id: searchParams.get("vacancyId") });
      setIsRightSidebarOpen(true);
    }
  }, []);

  return (
    <>
      {vacanciesData?.items.length || isVacanciesLoading ? (
        <table className={style.VacationTable}>
          <colgroup>
            <col width="20%" />
            <col width="15%" />
            <col width="15%" />
            <col width="15%" />
            <col width="15%" />
            <col width="15%" />
            <col width="150px" />
          </colgroup>

          <thead>
            <tr>{tableHead.map((element: any) => element)}</tr>
          </thead>
          <tbody>
            {isVacanciesLoading
              ? // Loading
                new Array(10).fill(null).map((it: any, idx: number) => {
                  return (
                    <tr key={idx} className={"border-top"}>
                      <td>
                        <Skeleton
                          height={32}
                          radius="r4"
                          shape="line"
                          variant="secondary"
                        />
                      </td>
                      <td>
                        <Skeleton
                          height={32}
                          radius="r4"
                          shape="line"
                          variant="secondary"
                        />
                      </td>
                      <td>
                        <Skeleton
                          height={32}
                          radius="r4"
                          shape="line"
                          variant="secondary"
                        />
                      </td>
                      <td>
                        <Skeleton
                          height={32}
                          radius="r4"
                          shape="line"
                          variant="secondary"
                        />
                      </td>
                      <td>
                        <Skeleton
                          height={32}
                          radius="r4"
                          shape="line"
                          variant="secondary"
                        />
                      </td>
                      <td>
                        <Skeleton
                          height={32}
                          radius="r4"
                          shape="line"
                          variant="secondary"
                        />
                      </td>
                      <td>
                        <Skeleton
                          height={32}
                          radius="r4"
                          shape="line"
                          variant="secondary"
                        />
                      </td>
                    </tr>
                  );
                })
              : vacanciesData?.items.map((item: any, idx: number) => {
                  const countOfDays = getCountOfDaysInWork(
                    item.inProgressDays,
                    item.closePeriodDays,
                  );
                  return (
                    <tr
                      key={idx}
                      className={cx("border-top", style.VacationListTable)}
                      onClick={() => {
                        setIsRightSidebarOpen(true);
                        changeSelectedTableItem(item);
                      }}
                    >
                      <td className="truncate-cell" title={item.title}>
                        {item.title}
                      </td>
                      <td
                        className="truncate-cell"
                        title={item.managerFullName}
                      >
                        {item.managerFullName || "-"}
                      </td>
                      <td
                        className="truncate-cell"
                        title={dateToRu(item.createDate)}
                      >
                        {dateToRu(item.createDate)}
                      </td>
                      <td className="truncate-cell" title={item.location}>
                        {item.location || "-"}
                      </td>
                      <td>
                        <span
                          className={cx(
                            countOfDays.isExpired ? "text-critical-base" : "",
                          )}
                        >
                          {`${countOfDays.daysInWork}`}
                        </span>
                      </td>
                      <td>
                        <VacancyBadge badge={item} />
                      </td>
                      <td className="pr-4 text-right p-4">
                        {!isManagerOrWatcher && (
                          <>
                            <Button
                              className={cx(
                                "mr-4",
                                "p-6",
                                "text-black",
                                "bg-content-2",
                              )}
                              iconLeft={<GlyphSwap />}
                              onClick={(e?: React.MouseEvent) => {
                                e?.stopPropagation();
                                setIsModalStatusEditOpen(true);
                                changeSelectedTableItem(item);
                              }}
                              variant="secondary"
                            ></Button>
                            <Button
                              className={cx(
                                "p-6",
                                "text-black",
                                "bg-content-2",
                              )}
                              iconLeft={<GlyphEdit />}
                              onClick={() => {
                                navigate(
                                  `${ROUTE_VACANCIES_UPDATE}/${item.id}`,
                                );
                              }}
                              variant="secondary"
                            ></Button>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      ) : (
        <NoData />
      )}

      {pageCount > 1 ? (
        <Pagination
          className="pl-0 pagination pb-0"
          currentNumber={queryParams.pageNum}
          onChange={function ka(page: any) {
            setQueryParams({ ...queryParams, pageNum: page });
          }}
          total={pageCount}
        />
      ) : (
        ""
      )}

      {isModalStatusEditOpen ? (
        <ChangeStatusPopup
          clickOnClose={(needUpdateList = false) => {
            setIsModalStatusEditOpen(false);
            if (needUpdateList) {
              setTimeout(() => {
                setQueryParams(() => {
                  return {
                    ...queryParams,
                    rnd: generateRandomIdByLength(10),
                  };
                });

                if (udpateCardCallbackAfterChangeStatus) {
                  udpateCardCallbackAfterChangeStatus();
                }
              }, 300);
            }
          }}
          vacancy={selectedTableItem}
        />
      ) : null}

      {ReactDOM.createPortal(
        <>
          <RightSidebar
            onClose={() => {
              setSearchParams({});
              handleRightSidebarClose();
              store.dispatch(resetToInitState(null));
            }}
            show={isRightSidebarOpen}
          >
            {isRightSidebarOpen ? (
              <VacationCard
                openUpdateStatus={(callbackStatusUpdate: any) => {
                  setNewCallbackForUpdateCard(callbackStatusUpdate);
                  setIsModalStatusEditOpen(true);
                }}
                vacancy={selectedTableItem}
              />
            ) : null}
          </RightSidebar>
        </>,
        document.body,
      )}
    </>
  );
};
export default List;
