import { getFileExtension } from "@aurora/components/dist/UploadedFile/helpers";
import { icons } from "@aurora/components/dist/UploadedFile/store";
import { FC } from "react";

interface Props {
  fileName: string;
}
export const DocIcon: FC<Props> = ({ fileName }) => {
  const ext = fileName === "notFound" ? "notFound" : getFileExtension(fileName);
  const IconComponent = icons[ext as keyof typeof icons] ?? icons.commonDoc;
  return <IconComponent />;
};
