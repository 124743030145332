import "./index.scss";
import ContainerRouter from "./routes";
import { SnackbarsProvider } from "@aurora/components";
import "@aurora/components/dist/style.css";
import { ThemeProvider, setupAuroraUIkit, AuroraConfig } from "@aurora/core";
import "@aurora/core/dist/style.css";
import "@aurora/deprecated/dist/style.css";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { store } from "core/store";
import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

const config: AuroraConfig = {
  fonts: {
    primary: {
      medium: {
        urls: ["/fonts/golos-ui_medium.woff2", "/fonts/golos-ui_medium.woff"],
      },
      regular: {
        urls: ["/fonts/golos-ui_regular.woff2", "/fonts/golos-ui_regular.woff"],
      },
      semiBold: {
        urls: [
          "/fonts/golos-text_demibold.woff2",
          "/fonts/golos-text_demibold.woff",
        ],
      },
    },
  },
  forcePrimaryFont: true,
  theme: "vHrLight",
};

setupAuroraUIkit(config);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

interface State {
  store: ToolkitStore;
}
export const Context = createContext<State>({
  store,
});

root.render(
  <ThemeProvider theme="vHrLight">
    <Provider store={store}>
      <SnackbarsProvider>
        <ContainerRouter />
      </SnackbarsProvider>
    </Provider>
  </ThemeProvider>,
);
