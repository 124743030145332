import { createSlice } from "@reduxjs/toolkit";

interface IUserSlice {
  user: any;
  isManagerOrWatcher: boolean;
}

const initialState: IUserSlice = { isManagerOrWatcher: false, user: null };

export const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {
    isCatetgoryManagerOrWatcher: (state, action) => {
      state.isManagerOrWatcher = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { isCatetgoryManagerOrWatcher, setUser } = userSlice.actions;
export default userSlice.reducer;
