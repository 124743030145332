import { formatISODate } from "../../../../services/date.service";
import {
  Button,
  Checkbox,
  InputDatepicker,
  Multiselect,
  MultiselectData,
} from "@aurora/components";
import { GlyphSearch } from "@aurora/icons";
import { useCandidateAutocompleteQuery } from "core/api/candidate/candidate";
import { FC, useEffect, useState } from "react";

interface ICandidatesProps {
  setQueryParams: (params: any) => void;
}

interface State {
  CandidateField: string;
  datepickerValue: (Date | null)[];
  has_new_message: boolean;
}

function initialState(): State {
  return {
    CandidateField: "",
    datepickerValue: [null, null],
    has_new_message: false,
  };
}

const FilterSideBar: FC<ICandidatesProps> = ({ setQueryParams }) => {
  const [state, setState] = useState(initialState);
  const [needClear, setNeedClear] = useState(false);
  const [isFirstLoad, changeFirstLoad] = useState(true);

  const [autocompleteCandidate, changeAutocompleteCandidate] = useState("");
  const [candidateData, setCandidateData] = useState<MultiselectData[]>([]);

  const {
    data: candidateAutocompleteData,
    error: candidateAutocompleteError,
    isLoading: candidateAutocompleteLoading,
  } = useCandidateAutocompleteQuery(autocompleteCandidate, {
    skip: autocompleteCandidate.length < 3,
  });

  useEffect(() => {
    if (candidateAutocompleteError)
      console.error("candidateAutocompleteError", candidateAutocompleteError);
  }, [candidateAutocompleteError]);

  const handleChange = (field: keyof State, value: any) => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };

  const handleSubmit = () => {
    if (isFirstLoad) {
      changeFirstLoad(false);
      return;
    }
    setQueryParams((oldParams: any) => {
      return {
        ...oldParams,
        has_new_message: state.has_new_message,
        pageNum: 1,
        personNames: candidateData.map(candidate => candidate.value),
        resumeUploadPeriodEnd: formatISODate(state.datepickerValue[1]),
        resumeUploadPeriodStart: formatISODate(state.datepickerValue[0]),
      };
    });
  };

  const handleReset = () => {
    setCandidateData([]);
    setState(initialState());
    setNeedClear(true);
  };

  useEffect(() => {
    if (!needClear) return;
    setNeedClear(false);
  }, [needClear]);

  useEffect(() => {
    handleSubmit();
  }, [candidateData, state]);

  return (
    <>
      <div className="mb-8">
        <Multiselect
          addonBefore={<GlyphSearch />}
          className="mb-0"
          data={candidateAutocompleteData || []}
          indent={false}
          loading={candidateAutocompleteLoading}
          onChange={setCandidateData}
          onInputChange={changeAutocompleteCandidate}
          placeholder="Фамилия кандидата"
          shape="select"
          showArrow={false}
          value={candidateData}
        />
      </div>
      <div className="mb-8">
        <InputDatepicker
          classNameBackgroundColor={undefined}
          dates={state.datepickerValue}
          disabled={false}
          onBlurContainerField={undefined}
          onFocusContainerField={undefined}
          onSubmit={dates => handleChange("datepickerValue", dates)}
          placeholder={"Дата резюме"}
        />
      </div>
      <div className="mb-8">
        <Checkbox
          checked={state.has_new_message}
          label="Есть новые сообщения"
          onChange={() =>
            handleChange("has_new_message", !state.has_new_message)
          }
        />
      </div>
      <Button onClick={handleReset} variant="secondary">
        Сбросить
      </Button>
    </>
  );
};
export default FilterSideBar;
