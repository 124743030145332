import { ROUTE_SETTINGS_CREATE } from "../../../constants/routes";
import FilterSideBar from "./FilterSideBar/FilterSideBar";
import style from "./Settings.module.scss";
import { GlyphPlus } from "@aurora/icons";
import cx from "classnames";
import AdditionallyMenu from "components/BaseComponents/AdditionallyMenu";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

const Settings: FC = () => {
  const [queryParams, setQueryParams] = useState({
    pageNum: 1,
    pageSize: 10,
    rnd: "",
  });
  const navigate = useNavigate();

  return (
    <>
      <div className={cx(style.CandidatesHeader)}>
        <h1 className={cx("my-0 text-black")}>Сотрудники</h1>

        <div>
          <AdditionallyMenu
            btn={{
              className: cx(style.CreateButton, "px-12"),
              icon: <GlyphPlus />,
              name: "Добавить сотрудника",
              onClick: () => {
                navigate(ROUTE_SETTINGS_CREATE);
              },
              shape: "circle",
            }}
          />
        </div>
      </div>
      <div className={cx(style.CandidatesList, "text-center")}>
        <div className={style.CandidatesListLeftSide}>
          <h2 className="text-accent">Данных нет</h2>
        </div>
        <div className={style.CandidatesListRightSide}>
          <FilterSideBar setQueryParams={setQueryParams} />
        </div>
      </div>
    </>
  );
};

export default Settings;
