import { oauth2Api } from "../api/oauth2";
import authSliceReducer from "./reducers/AuthSlice";
import contextMenuSliceReducer from "./reducers/ContextMenuSlice";
import filterSelectedSliceReducer from "./reducers/FilterSelectedSlice";
import userDataSliceReducer from "./reducers/UserDataSlice";
import {
  Action,
  ThunkAction,
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const rootReducer = combineReducers({
  authSlice: authSliceReducer,
  contextMenuSlice: contextMenuSliceReducer,
  filterSelectedSlice: filterSelectedSliceReducer,
  userDataSlice: userDataSliceReducer,
  [oauth2Api.reducerPath]: oauth2Api.reducer,
});

export const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(oauth2Api.middleware),
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
