type Item = {
  id: string;
  value?: string;
};

export const isEqual = function (
  arr1: Item[] | undefined,
  arr2: Item[] | undefined,
  equalIds?: boolean,
) {
  if (arr1 === undefined && arr2 === undefined) {
    return true;
  }
  if (
    (arr1 === undefined && arr2 !== undefined) ||
    (arr1 !== undefined && arr2 === undefined)
  ) {
    return false;
  }

  let a1: Item[] = [];
  let a2: Item[] = [];
  if (equalIds === true) {
    a1 = [...(arr1 as Item[]).map((a: Item) => ({ id: a.id }))] as Item[];
    a2 = [...(arr2 as Item[]).map((a: Item) => ({ id: a.id }))] as Item[];
  } else {
    a1 = [...(arr1 as Item[])];
    a2 = [...(arr2 as Item[])];
  }
  a1.sort((a, b) => a.id.localeCompare(b.id));
  a2.sort((a, b) => a.id.localeCompare(b.id));
  return JSON.stringify(a1) === JSON.stringify(a2);
};

export function diffArrayOfIdValue(initArr: Item[], diffArr: Item[]) {
  const diff: object[] = [];

  for (const obj of initArr) {
    if (!diffArr.some(item => item.id === obj.id && item.value === obj.value)) {
      diff.push(obj);
    }
  }

  for (const obj of diffArr) {
    if (!initArr.some(item => item.id === obj.id && item.value === obj.value)) {
      diff.push(obj);
    }
  }

  return diff;
}
