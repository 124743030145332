import FirstMessage from "./FirstMessage/Tab";
import style from "./Modal.module.scss";
import Status from "./Status/Tab";
import { Badge, Button, Popup, Tab, Tabs } from "@aurora/components";
import cx from "classnames";
import React, { FC, useState } from "react";

interface Props {
  clickOnClose: any;
  onSuccessModal?: any;
  candidates?: any;
}
const tabs = {
  0: "Статус",
  1: "Первое сообщение",
};
const MassEffectModal: FC<Props> = ({ clickOnClose }) => {
  const [activeTab, changeActiveTab] = useState(0);
  return (
    <>
      <Popup
        classNameContent={cx(style.modal, "p-20")}
        classNameWrapper="p-0"
        onClickCloseCross={clickOnClose}
        onClickOutside={clickOnClose}
      >
        <>
          <div className={"title-and-buttons-align"}>
            <h1 className={cx("my-0 text-accent")}>Массовое действие</h1>
            <div>
              <Button onClick={clickOnClose} variant="secondary">
                Отмена
              </Button>
              <Button
                className={style.ButtonSuccess}
                onClick={() => {
                  return;
                }}
                variant="primary"
              >
                Выбрать кандидатов
              </Button>
            </div>
          </div>
          <div className="mt-12">
            <Tabs
              className="mb-8"
              onChange={(v, value) => {
                changeActiveTab(value);
              }}
              value={activeTab}
              variant={"primary"}
            >
              <Tab active label={tabs[0]} value={0}></Tab>
              {/*заблокировано пока функционал не нужен, верстка готова*/}
              <Tab
                disabled={true}
                iconRight={<Badge label="feature" />}
                label={tabs[1]}
                value={1}
              ></Tab>
            </Tabs>
          </div>
          <div>
            {activeTab === 0 && <Status />}
            {/*todo: реализация на будущее*/}
            {activeTab === 1 && <FirstMessage />}
          </div>
        </>
      </Popup>
    </>
  );
};

export default MassEffectModal;
