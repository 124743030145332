import { useCandidateStatusHistoryMutation } from "../../../../core/api/candidate/candidate";
import { useVacationCardMutation } from "../../../../core/api/vacancy/vacancy";
import { dateToRu } from "../../../../services/date.service";
import NoData from "../../../BaseComponents/NoData";
import { SkeletonTable } from "../../../BaseComponents/SkeletonTable/SkeletonTable";
import { ICardHistory } from "../types";
import styles from "./Card.module.scss";
import { FC, useEffect, useState } from "react";

const CardHistory: FC<ICardHistory> = ({ personId, vacancyIds }) => {
  const [candidateHistory, setCandidateHistory] = useState<any>();
  const [candidateVacancies, setCandidateVacancies] = useState<any>();
  const [loadCandidateStatusHistory] = useCandidateStatusHistoryMutation();
  const [loadVacationInfo] = useVacationCardMutation();

  if (!personId || !vacancyIds || vacancyIds.length === 0) {
    return <NoData />;
  }

  useEffect(() => {
    Promise.all([
      ...vacancyIds.map(vacancyId => {
        return loadCandidateStatusHistory({ personId, vacancyId });
      }),
    ]).then(result => {
      const ch: any = [];
      result.map((d: any) => {
        if (!d.data) return;
        d.data.map((c: any) => {
          ch.push(c);
        });
      });
      Promise.all([
        ...vacancyIds.map(vacancyId => {
          return loadVacationInfo({ vacancyId });
        }),
      ]).then(vacancies => {
        const vc: any = [];
        vacancies.map((v: any) => {
          if (!v.data) return;
          vc.push(v.data);
        });
        setCandidateVacancies(vc);
      });
      setCandidateHistory(ch);
    });
  }, []);

  return (
    <>
      <div className={styles.CandidateCardHistory}>
        <table>
          <colgroup>
            <col width={"25.8%"} />
            <col width={"11.8%"} />
            <col width={"34.4%"} />
            <col width={"28%"} />
          </colgroup>
          <thead>
            <tr>
              <th>Перевод статуса</th>
              <th>Дата события</th>
              <th>Комментарий</th>
              <th>Наименование вакансии</th>
            </tr>
          </thead>
          <tbody>
            {candidateHistory && candidateVacancies ? (
              candidateHistory.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{item.stateTitleTo}</td>
                    <td>{dateToRu(item.createDateTime)}</td>
                    <td className="truncate-cell">{item.comment || "—"}</td>
                    <td className="truncate-cell">
                      {candidateVacancies.find(
                        (el: any) => el.id === item.vacancyId,
                      )?.title || "-"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <SkeletonTable rows={4} />
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CardHistory;
