import {
  useAddNewLabelMutation,
  useLabelsByPartTextQuery,
} from "../../../core/api/base/other";
import {
  useBindLabelsToPersonMutation,
  useUnbindLabelsToPersonMutation,
} from "../../../core/api/candidate/candidate";
import {
  useBindLabelsToVacancyMutation,
  useUnbindLabelsToVacancyMutation,
} from "../../../core/api/vacancy/vacancy";
import { isEqual } from "../../../services/object.service";
import {
  snackbarFail,
  snackbarSuccess,
} from "../../../services/snackbar.service";
import style from "./Label.module.scss";
import { diffMultiselectData } from "./helpers";
import { LabelEntity } from "./types";
import {
  Confirm,
  InformationCell,
  Multiselect,
  MultiselectData,
  Text,
  Tooltip,
  useSnackbars,
} from "@aurora/components";
import { GlyphCheck } from "@aurora/icons";
import cx from "classnames";
import { DICTIONARY } from "constants/dictionary";
import React, { createRef, useEffect, useState } from "react";
import { validate as isValidUUID } from "uuid";

interface ILabelProps {
  initLabels: MultiselectData[];
  entity?: LabelEntity;
  needConfirmed?: boolean;
  personId?: string;
  vacancyId?: string;
  needSaveBt?: boolean;
  onLabelsChange?: (labels: MultiselectData[], unbind?: boolean) => void;
  eventSave?: boolean;
  maxLengthTagName?: number;
}

const inputRef = createRef<HTMLInputElement>();

const Label: React.FC<ILabelProps> = ({
  entity,
  eventSave,
  initLabels,
  maxLengthTagName = 30,
  needConfirmed,
  needSaveBt,
  onLabelsChange,
  personId,
  vacancyId,
}) => {
  //const [initLabels, setInitLabels] = useState<MultiselectData[]>([]);
  const [labels, setLabels] = useState<MultiselectData[]>(initLabels);
  const [labelRemove, setLabelRemove] = useState<MultiselectData>();
  const [showConfirmed, setShowConfirmed] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [labelAutocomplete, setLabelAutocomplete] = useState("");
  const [save, setEventSave] = useState(eventSave);

  const [bindLabelsToCandidate] = useBindLabelsToPersonMutation();
  const [bindLabelsToVacancy] = useBindLabelsToVacancyMutation();
  const { data: labelsAutocomplete, isLoading: autocompleteLoading } =
    useLabelsByPartTextQuery(labelAutocomplete, {
      skip: labelAutocomplete.length < 3 || labelAutocomplete.length > 30,
    });
  const [unbindLabelsToCandidate] = useUnbindLabelsToPersonMutation();
  const [unbindLabelsToVacancy] = useUnbindLabelsToVacancyMutation();
  const [addNewLabel] = useAddNewLabelMutation();

  const { snackbarInfo } = useSnackbars();
  // ограничиваем длинну вводимой метки
  const handlerKeyDown = (event: any) => {
    const { value } = event.target;
    if (value?.length > maxLengthTagName) {
      event.target.value = value.slice(0, maxLengthTagName);
    }
  };
  // region UseEffect handler onKeyDown
  useEffect(() => {
    const inputElement = inputRef.current;
    const originalHandler = inputElement?.onkeydown;

    inputElement?.addEventListener("input", handlerKeyDown);

    // Очистка при размонтировании для предотвращения утечки памяти
    return () => {
      inputElement?.removeEventListener("input", handlerKeyDown);
      if (originalHandler) inputElement.onkeydown = originalHandler;
    };
  }, [handlerKeyDown]);
  // endregion
  // region UseEffect on initLabels change
  useEffect(() => {
    setLabels(initLabels);
  }, [initLabels]);
  // endregion
  // region UseEffect on initLabels and labels change
  useEffect(() => {
    if (needSaveBt) {
      // отобразим кнопку сохранения, если массив меток не пустой
      setShowSaveBtn(!isEqual(labels, initLabels));
    }
  }, [labels, initLabels]);
  // endregion
  // region UseEffect on EventSave handler
  useEffect(() => {
    hideSaveBtn();
    if (save && (personId || vacancyId)) {
      const newLabels: MultiselectData[] = [
        ...labels.filter(label => !isValidUUID(label.id)),
      ];
      Promise.all([
        ...labels
          .filter(label => isValidUUID(label.id))
          .map(label => addNewLabel(label)),
      ]).then(result => {
        result.map((d: any) => {
          if (!d.data) return;
          newLabels.push({ ...d.data });
        });
        bindLabels([...newLabels])
          .then((result: any) => {
            if (result.data) {
              changeLabels(result.data.labels);
              if (onLabelsChange) {
                onLabelsChange(result.data.labels);
              }
              snackbarInfo?.show(snackbarSuccess(DICTIONARY.LABEL_ADD_TO_CARD));
            }
          })
          .catch(e => {
            console.log("Error", e);
            const fail: any = snackbarFail(DICTIONARY.FAIL);
            snackbarInfo?.show(fail);
          });
      });
    }
  }, [save]);
  // endregion
  // region Functions hidden save button
  function hideSaveBtn() {
    setShowSaveBtn(false);
    setEventSave(false);
  }
  // endregion
  // region Functions async Bind and Unbind labels
  async function bindLabels(dataLabels: MultiselectData[]) {
    // для всех меток id которых нет в initLabels сделаем привязку
    const labelIds = dataLabels
      .filter(
        label =>
          // исключим из выборки уже проинициализированные метки
          !initLabels.some((value: MultiselectData) => label.id === value.id),
      )
      .map(label => label.id);
    switch (entity) {
      case "person":
        return await bindLabelsToCandidate({
          labelIds,
          personId,
        });
      case "vacancy":
        return await bindLabelsToVacancy({
          labelIds,
          vacancyId,
        });
    }
  }
  async function unbindLabels(dataLabels: MultiselectData[]) {
    // исключим из удаления метки которые еще не сохранены на сервере и те которые не привязаны
    const unbindFromServer = dataLabels.filter(
      dl =>
        !isValidUUID(dl.id) &&
        initLabels.some((value: MultiselectData) => dl.id === value.id),
    );
    // список меток на удаление не пустой
    if (unbindFromServer.length > 0) {
      const labelIds = unbindFromServer.map(label => label.id);
      switch (entity) {
        case "person":
          return await unbindLabelsToCandidate({
            labelIds,
            personId,
          });
        case "vacancy":
          return await unbindLabelsToVacancy({
            labelIds,
            vacancyId,
          });
      }
    }
  }
  // endregion
  // region Functions handlers
  function changeLabels(newLabels: MultiselectData[]) {
    // ограничение на добавление более 20 меток
    const diffLabel = diffMultiselectData(newLabels, labels);
    const duplicateLabels =
      diffLabel.length > 0 &&
      labels.some((l: MultiselectData) => l.value === diffLabel[0].value);
    if (newLabels.length > 20 || duplicateLabels) return;
    if (onLabelsChange && !needSaveBt) {
      onLabelsChange(newLabels);
    }
    setLabels(newLabels);
  }
  function onRemoveLabels(e: MultiselectData | null) {
    if (e !== null) {
      setLabelRemove(e);
      if (needConfirmed) {
        setShowConfirmed(true);
      } else {
        changeLabelAfterRemove(e);
      }
    }
  }
  function handlerChange(e: React.SetStateAction<MultiselectData[]>) {
    if (e && labels && e.length > labels.length) {
      changeLabels(e as MultiselectData[]);
      setLabelAutocomplete("");
    }
  }
  function resetLabelRemove() {
    const rl = undefined;
    setLabelRemove(rl);
  }
  function backLabelIsCancel() {
    if (labelRemove) {
      const alreadyIn = labels.some(label => label.id === labelRemove.id);
      const newValues = alreadyIn ? [...labels] : [...labels, labelRemove];
      changeLabels(newValues);
    }
  }
  function changeLabelAfterRemove(labelRemove: MultiselectData) {
    changeLabels(labels.filter(l => l.id !== labelRemove?.id));
  }
  function onConfirm() {
    if (labelRemove) {
      // удаление метки без запроса, если в inti метки не оказалось
      if (!initLabels.some(label => label.id === labelRemove.id)) {
        changeLabelAfterRemove(labelRemove);
        resetLabelRemove();
        setShowConfirmed(false);
      } else {
        unbindLabels([labelRemove])
          .then((res: any) => {
            if (res.data) {
              changeLabelAfterRemove(labelRemove);
              if (onLabelsChange && res.data.labels) {
                onLabelsChange(res.data.labels, true);
              }
              snackbarInfo?.show(
                snackbarSuccess(DICTIONARY.LABEL_REMOVE_FROM_CARD),
              );
            } else {
              console.log("Error", res.error);
            }
          })
          .catch(e => {
            console.log("Error", e);
            let fail: any = null;
            if (e.errorCode === 403) {
              fail = snackbarFail("Недостаточно прав на выполнение операции");
              console.log("Forbidden", e.messages);
            } else {
              fail = snackbarFail(DICTIONARY.FAIL);
            }
            snackbarInfo?.show(fail);
          })
          .finally(() => {
            resetLabelRemove();
            setShowConfirmed(false);
          });
      }
    } else {
      setShowConfirmed(false);
    }
  }
  function onCancel() {
    if (labelRemove) {
      backLabelIsCancel();
    }
    resetLabelRemove();
    setShowConfirmed(false);
  }
  function onClick(e?: React.MouseEvent) {
    e?.stopPropagation();
    e?.preventDefault();
    setEventSave(true);
    resetLabelRemove();
    return false;
  }
  // endregion
  return (
    <div className={"mt-16 border-top"}>
      <InformationCell
        label={
          <Text
            className={style.Tooltip}
            color={"colorFixedBlack"}
            tag={"div"}
            type={"lineRegular"}
          >
            Метки
            <Tooltip
              className="ml-2"
              content={
                <>
                  <p>
                    На карточку можно добавить до <strong>20 меток</strong>.
                  </p>
                  <p>
                    Длина одной метки не должна превышать{" "}
                    <strong>30 символов</strong>.
                  </p>
                </>
              }
              triggerOn="hover"
            />
          </Text>
        }
        separator={false}
        title={
          <Multiselect
            ref={inputRef}
            addonAfter={
              needSaveBt && (
                <a
                  className={!showSaveBtn ? style.BtnSaveHidden : ""}
                  onClick={onClick}
                  title={"Сохранить метки"}
                >
                  <GlyphCheck color={"#5BBD2C"} height={30} width={30} />
                </a>
              )
            }
            className={cx(style.LabelMultiselectBadgeColor, "pl-0")}
            classNameBackgroundColor={
              needSaveBt ? style.MainBackgroundColor : ""
            }
            data={labelsAutocomplete || []}
            indent={false}
            loading={autocompleteLoading}
            onChange={handlerChange}
            onInputChange={setLabelAutocomplete}
            onRemove={onRemoveLabels}
            placeholder="Выберите метку"
            shape="autocomplite"
            showArrow={false}
            value={labels}
          />
        }
      />
      {/* дополнительная проверка needConfirmed из props */}
      {showConfirmed && needConfirmed && (
        <Confirm
          confirmBtnText={"Да"}
          onCancel={onCancel}
          onConfirm={onConfirm}
          open={showConfirmed}
          title={"Вы действительно хотите снять метку с карточки?"}
        />
      )}
    </div>
  );
};

export default Label;
