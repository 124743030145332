import useErrorNotification from "../../../../hooks/useErrorNotification";
import style from "./CreateOrUpdateEmployee.module.scss";
import CreateOrUpdateEmployeeSkeleton from "./Skeleton/Skeleton";
import {
  Button,
  Input,
  Select,
  useSnackbars,
  Accordion,
  ActionCell,
  Checkbox,
} from "@aurora/components";
import cx from "classnames";
import { DICTIONARY } from "constants/dictionary";
import {
  useGetUserCategoriesMutation,
  useGetUserPermissionMutation,
  useIsAvailableTypeMutation,
  useCreateUserMutation,
} from "core/api/user/user";
import { useVacationCardMutation } from "core/api/vacancy/vacancy";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checkEmail, checkUsername } from "services/check.service";
import { snackbarFail, snackbarSuccess } from "services/snackbar.service";

interface Props {
  isEdit?: boolean;
}
type availableSteps = "initial" | "permission" | "additional";

type TInitialFormState = {
  category: string;
  email: string;
  firstName: string;
  id: number | null;
  lastName: string;
  middleName: string;
  password: string;
  permissions: any[]; // Можете заменить на конкретный тип
  phone: string;
  username: string;
};

const initialFormState: TInitialFormState = {
  category: "ADMIN",
  email: "",
  firstName: "",
  id: null,
  lastName: "",
  middleName: "",
  password: "",
  permissions: [],
  phone: "",
  username: "",
};

interface IForm {
  name: string;
  isImportant: boolean;
  onChange: any;
  value: string;
  type?: string;
  description?: boolean;
  hasError?: boolean;
}

const InputValidate: FC<IForm> = ({
  description,
  hasError,
  isImportant,
  name,
  onChange,
  type,
  value,
}) => {
  return (
    <Input
      description={hasError ? description : ""}
      label={
        <span>
          {name}{" "}
          {isImportant ? <span className={"text-critical-base"}>*</span> : null}
        </span>
      }
      onChange={onChange}
      placeholder="Введите"
      status={hasError ? "error" : ""}
      type={type}
      value={value}
    />
  );
};

const CreateOrUpdateEmployee: FC<Props> = () => {
  const { snackbarInfo } = useSnackbars();

  const navigate = useNavigate();
  const routeParams = useParams();
  const errorNotification = useErrorNotification();

  const [step, changeStep] = useState<availableSteps>("initial");

  const [isFirstStep, changeFirstStep] = useState(true);

  const [isValidEmail, changeIsValidEmail] = useState(true);
  const [isValidLogin, changeIsValidLogin] = useState(true);

  const [isAvailableSomeField, setIsAvailableSomeField] = useState({
    email: false,
    phone: false,
    username: false,
  });
  const [selectedOptionCategories, setSelectedOptionCategories] =
    useState<any>(null);

  const [user, setUser]: any = useState(initialFormState);
  const [permissions, setPermissionList]: any = useState([]);
  const [categoriesData, setCategoriesData]: any = useState([]);

  const [isVacationCardLoading, changeVacationCardLoading] = useState(true);

  const [hasAnyChanges, changeHasAnyChanges] = useState(false);
  const [isLoading, changeLoading] = useState(true);
  const [isLoadingOfChecking, changeLoadingOfChecking] = useState(false);
  const [vacationCardData, changeVacationCardData]: any = useState(null);

  const [isAvailableField] = useIsAvailableTypeMutation();
  const [getPermissions] = useGetUserPermissionMutation();
  const [getCategories] = useGetUserCategoriesMutation();

  const [createUser] = useCreateUserMutation();

  const isEdit = !!routeParams.vacancyId;
  const debounceTimerFields: any = {
    email: null,
    phone: null,
    username: null,
  };

  const [loadDataCard] = useVacationCardMutation();

  function loadCardDataInfo() {
    changeHasAnyChanges(false);
    loadDataCard({ vacancyId: routeParams.vacancyId }).then(
      (vacationCardData: any) => {
        vacationCardData = vacationCardData.data;
        changeVacationCardData(vacationCardData);
      },
    );
  }

  useEffect(() => {
    // Загрузка основной информации и файла
    Promise.all([getPermissions(), getCategories()]).then(
      ([permissions, categories]: any) => {
        if (permissions.data) {
          const pexData = permissions.data;
          setPermissionList(
            pexData.map((item: any) => {
              return {
                ...item,
                child: item.child.map((ch: any) => {
                  return { ...ch, isChecked: false };
                }),
              };
            }),
          );
        }

        if (categories.data) {
          setCategoriesData(categories.data);
          setSelectedOptionCategories(categories.data[0]);
        }
        changeLoading(false);
      },
    );

    if (isEdit) {
      loadCardDataInfo();
    } else {
      changeHasAnyChanges(true);
    }
  }, []);

  useEffect(() => {
    // Редактирование существующей карточки, логика отработает после того как все данные будут загружены
    if (!isEdit) return;
    if (isVacationCardLoading) return;

    setUser({
      categoryId: vacationCardData.categoryId,
      description: vacationCardData.description,
      link: vacationCardData.link,
      location: vacationCardData.location,
      managerContacts: vacationCardData.managerContacts,
      managerId: vacationCardData.managerId,
      options: vacationCardData.options,
      requirements: vacationCardData.requirements,
      responsibilities: vacationCardData.responsibilities,
      title: vacationCardData.title,
    });

    changeLoading(false);
  }, [isVacationCardLoading]);

  useEffect(() => {
    if (!isEdit || isLoading) return;
    hasAnyChangesVacancy();
  }, [user, selectedOptionCategories]);

  function hasAnyChangesVacancy() {
    let hasChanges = false;

    Object.keys(user).forEach((key: string) => {
      if (vacationCardData[key] !== user[key]) hasChanges = true;
    });

    if (vacationCardData.categoryId !== selectedOptionCategories?.id) {
      hasChanges = true;
    }

    changeHasAnyChanges(hasChanges);
  }

  function toggleCardSelect(groupIdx: number, childIdx: number) {
    const pex = [...permissions];
    pex[groupIdx].child[childIdx].isChecked =
      !pex[groupIdx].child[childIdx].isChecked;
    setPermissionList(pex);
  }

  function isDisabledNextButton() {
    if (step === "initial") {
      return !(
        (
          user.firstName &&
          user.lastName &&
          user.email &&
          isValidEmail && // Валидный ли email
          isValidLogin && // Валидный ли логин
          user.phone &&
          user.username &&
          user.password &&
          selectedOptionCategories &&
          hasAnyChanges &&
          !isAvailableSomeField.email && // Email не занят
          !isAvailableSomeField.username && // Login не занят
          !isAvailableSomeField.phone && // Телефон не занят
          !isLoadingOfChecking
        ) // Нет загрузки
      );
    }

    if (step === "permission") {
      let isChecked = false;
      // Проверяем все ли поля активны
      permissions.forEach((pexGroup: any) => {
        pexGroup.child.forEach((child: any) => {
          if (child.isChecked) isChecked = true;
        });
      });

      return !isChecked;
    }
  }

  async function createEmployeeOrUpdate() {
    let employee: any = null;
    changeLoading(true);
    let successText = "";

    if (!isEdit) {
      const results = await Promise.all([
        isAvailableField({
          type: "email",
          value: user.email,
        }),
        isAvailableField({
          type: "username",
          value: user.username,
        }),
        isAvailableField({
          type: "phone",
          value: user.phone,
        }),
      ]);
      if (
        results.filter((resItem: any) => {
          return resItem?.data?.status !== "AVAILABLE";
        }).length
      ) {
        const fail: any = snackbarFail(
          DICTIONARY.FAIL_CREATE_ACCOUNT_WITH_THE_SAME_FIELDS,
        );
        snackbarInfo?.show(fail);
        changeLoading(false);
        return;
      }

      employee = await createUser({
        ...user,
        permissions: permissions.reduce(
          (pexList: Array<string>, pexItem: any) => {
            pexList.push(
              ...pexItem.child
                .filter((pChild: any) => pChild.isChecked)
                .map((p: any) => p.code),
            );

            return pexList;
          },
          [],
        ),
      });
      successText = DICTIONARY.SUCCESS_USER_CREATED;
    }

    if (employee.error) {
      errorNotification(employee.error);
      changeLoading(false);
      return;
    }

    snackbarInfo?.show(snackbarSuccess(successText));
    changeLoading(false);

    navigate(-1);
  }

  const debounceCheckFieldAvailable = useCallback(
    (key: string, value: string) => {
      changeLoadingOfChecking(true);
      clearTimeout(debounceTimerFields[key]);

      debounceTimerFields[key] = setTimeout(async () => {
        await checkFieldAvailable(key, value);
      }, 500);
    },
    [],
  );

  const checkFieldAvailable = async (key: string, value: string) => {
    const result: any = await isAvailableField({
      type: key,
      value,
    });

    changeLoadingOfChecking(false);
    if (result.data) {
      setIsAvailableSomeField((oldValues: any) => {
        return {
          ...oldValues,
          [key]: !(result?.data?.status === "AVAILABLE"),
        };
      });
    } else {
      setIsAvailableSomeField((oldValues: any) => {
        return {
          ...oldValues,
          [key]: false,
        };
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <CreateOrUpdateEmployeeSkeleton />
      ) : (
        <div className={style.CreateOrUpdateEmployee}>
          <div className={style.CreateOrUpdateEmployeeHeader}>
            <h1 className={cx("my-0 text-black")}>Добавление сотрудника</h1>

            <div className={style.ButtonGroup}>
              <Button
                className={cx(style.ButtonStyles, "mr-4")}
                onClick={() => {
                  if (step === "permission") {
                    changeStep("initial");
                    changeFirstStep(false);
                  } else {
                    navigate(-1);
                  }
                }}
                variant="secondary"
              >
                {step === "permission" ? "Назад" : "Отмена"}
              </Button>

              <Button
                className={style.ButtonStyles}
                disabled={isDisabledNextButton()}
                loading={isLoadingOfChecking}
                onClick={() => {
                  switch (step) {
                    case "initial":
                      {
                        changeStep("permission");
                        if (isFirstStep) {
                          setPermissionList(
                            permissions.map((item: any) => {
                              return {
                                ...item,
                                child: item.child.map((ch: any) => {
                                  const isChecked =
                                    selectedOptionCategories?.permissions[
                                      ch.code
                                    ];
                                  return { ...ch, isChecked };
                                }),
                              };
                            }),
                          );
                        }
                      }
                      break;
                    case "permission": {
                      // Save Data
                      createEmployeeOrUpdate();
                    }
                  }
                }}
                variant="primary"
              >
                {step === "initial" ? "Далее" : "Сохранить"}
              </Button>
            </div>
          </div>

          {step === "initial" && (
            <div className={cx(style.CreateOrUpdateEmployeeForm, "px-8")}>
              <div
                className={cx("mt-16", style.CreateOrUpdateEmployeeFormField)}
              >
                {[
                  { isImportant: true, key: "lastName", name: "Фамилия" },
                  { isImportant: true, key: "firstName", name: "Имя" },
                  { isImportant: false, key: "middleName", name: "Отчество" },
                ].map((item: any) => {
                  return (
                    <div key={item.key}>
                      <InputValidate
                        isImportant={item.isImportant}
                        name={item.name}
                        onChange={(e: any) => {
                          setUser({
                            ...user,
                            [item.key]: e.target.value,
                          });
                        }}
                        value={user[item.key]}
                      />
                    </div>
                  );
                })}
              </div>
              <div
                className={cx(
                  "mt-8 pt-8",
                  style.CreateOrUpdateEmployeeFormField,
                  "border-top",
                )}
              >
                {[
                  {
                    description:
                      "В системе есть пользователь с аналогичным телефоном",
                    hasError: isAvailableSomeField.phone,
                    key: "phone",
                    name: "Телефон",
                  },
                  {
                    description: !isValidEmail
                      ? DICTIONARY.NOT_VALID_EMAIL
                      : "В системе есть пользователь с аналогичной почтой",
                    hasError: isAvailableSomeField.email || !isValidEmail,
                    key: "email",
                    name: "E-mail",
                    onChange: (value: string) => {
                      changeIsValidEmail(checkEmail(value));
                    },
                  },
                ].map((item: any) => {
                  return (
                    <div key={item.key}>
                      <InputValidate
                        description={item.description}
                        hasError={item.hasError}
                        isImportant={true}
                        name={item.name}
                        onChange={async (e: any) => {
                          setUser({
                            ...user,
                            [item.key]: e.target.value,
                          });
                          debounceCheckFieldAvailable(item.key, e.target.value);
                          item.onChange && item.onChange(e.target.value);
                        }}
                        value={user[item.key]}
                      />
                    </div>
                  );
                })}
                <div>
                  <Select
                    className={cx("p-0")}
                    data={categoriesData}
                    label={
                      <span>
                        Роль сотрудника{" "}
                        <span className={"text-critical-base"}>*</span>
                      </span>
                    }
                    onChange={(categories: any) => {
                      setSelectedOptionCategories(categories);
                      setUser((oldUserData: TInitialFormState) => {
                        return { ...oldUserData, category: categories.id };
                      });
                      changeFirstStep(true);
                    }}
                    shape="autocomplite"
                    value={selectedOptionCategories}
                  />
                </div>
              </div>

              <div
                className={cx("mt-16", style.CreateOrUpdateEmployeeFormField)}
              >
                {[
                  {
                    description: !isValidLogin
                      ? DICTIONARY.NOT_VALID_LOGIN
                      : "В системе есть пользователь с аналогичным логином",
                    hasError: isAvailableSomeField.username || !isValidLogin,
                    key: "username",
                    name: "Логин",
                    onChange: (value: string) => {
                      changeIsValidLogin(checkUsername(value));
                    },
                  },
                  { key: "password", name: "Пароль", type: "password" },
                ].map((item: any) => {
                  return (
                    <div key={item.key}>
                      <InputValidate
                        description={item.description}
                        hasError={item.hasError}
                        isImportant={true}
                        name={item.name}
                        onChange={async (e: any) => {
                          setUser({
                            ...user,
                            [item.key]: e.target.value,
                          });
                          if (item.key === "username") {
                            debounceCheckFieldAvailable(
                              item.key,
                              e.target.value,
                            );
                          }

                          item.onChange && item.onChange(e.target.value);
                        }}
                        type={item.type}
                        value={user[item.key]}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {step === "permission"
            ? permissions.map((group: any, groupIdx: number) => {
                return (
                  <div key={group.code}>
                    <Accordion
                      className={"py-0"}
                      classNameContentContainer={
                        style.AccordionContentContainer
                      }
                      isOpen={true}
                      title={<h2>{group.title}</h2>}
                    >
                      <div className="w-100">
                        {group.child.map((section: any, childIdx: number) => {
                          return (
                            <ActionCell
                              key={childIdx}
                              addonRight={
                                <Checkbox
                                  checked={section.isChecked}
                                  onChange={() => {
                                    toggleCardSelect(groupIdx, childIdx);
                                  }}
                                />
                              }
                              className={cx("py-12", "border-bottom")}
                              classNameTitle={
                                style.AccordionContentContainerActionTitle
                              }
                              onClick={() =>
                                toggleCardSelect(groupIdx, childIdx)
                              }
                              separator={false}
                              title={section.title}
                              variant="tradeOrder"
                            />
                          );
                        })}
                      </div>
                    </Accordion>
                  </div>
                );
              })
            : ""}
        </div>
      )}
    </>
  );
};

export default CreateOrUpdateEmployee;
