import { createSlice } from "@reduxjs/toolkit";

interface IContextMenuSlice {
  x: number;
  y: number;
  visible: boolean;
}
const initialState: IContextMenuSlice = {
  visible: false,
  x: 0,
  y: 0,
};
export const contextMenuSlice = createSlice({
  initialState,
  name: "contextMenu",
  reducers: {
    setState: (state, action) => {
      state.x = action.payload.x;
      state.y = action.payload.y;
      state.visible = action.payload.visible;
    },
    setVisible: (state, action) => {
      state.visible = action.payload as boolean;
    },
  },
});
export const { setState, setVisible } = contextMenuSlice.actions;
export default contextMenuSlice.reducer;
