import { Skeleton } from "@aurora/components";
import { FC, memo } from "react";

const LoaderFilter: FC = () => {
  return (
    <>
      <>
        {new Array(8).fill(null).map((i, idx) => {
          return (
            <div key={idx} className="mb-8">
              <Skeleton
                height={32}
                radius="r5"
                shape="line"
                variant="primary"
              />
            </div>
          );
        })}
      </>
    </>
  );
};

export default memo(LoaderFilter);
