import { useVacanciesListQuery } from "../../../../core/api/vacancy/vacancy";
import { generateRandomIdByLength } from "../../../../services/generate.service";
import CandidateBadge from "../../../BaseComponents/CandidateBadge";
import NoData from "../../../BaseComponents/NoData";
import ChangeStatusPopup from "../../../BaseComponents/Popups/VacancyChangeStatus/Modal";
import { SkeletonTable } from "../../../BaseComponents/SkeletonTable/SkeletonTable";
import VacancyBadge from "../../../BaseComponents/VacancyBadge";
import style from "./Card.module.scss";
import { Button, Pagination } from "@aurora/components";
import { GlyphSwap } from "@aurora/icons";
import cx from "classnames";
import { ROUTE_VACANCIES } from "constants/routes";
import { FC, useEffect, useState } from "react";

interface Props {
  candidate: any;
  updateCandidateData?: any;
  vacancyIds?: any;
}
const CardVacancies: FC<Props> = ({
  candidate,
  updateCandidateData,
  vacancyIds,
}) => {
  const tableHead = [
    "Наименование вакансии",
    "Дата создания",
    "Статус вакансии",
    "Статус кандидата",
    "",
  ];
  const [isModalStatusEditOpen, setIsModalStatusEditOpen] = useState(false);

  const [queryParams, setQueryParams] = useState({
    pageNum: 1,
    pageSize: 10,
    rnd: generateRandomIdByLength(10),
    vacancyIds,
  });
  const [pageCount, setPageCount] = useState(0);
  const [selectedCandidate, setSelectedCandidate] = useState<string>("");
  const [selectedVacancy, setSelectedVacancy] = useState<string>("");

  const {
    data: vacanciesData,
    error: vacanciesError,
    isLoading: isVacanciesLoading,
  } = useVacanciesListQuery(queryParams, {
    refetchOnMountOrArgChange: true,
    skip: queryParams.vacancyIds?.length === 0,
  });

  useEffect(() => {
    if (vacanciesError) console.error("vacanciesError", vacanciesError);
  }, [vacanciesError]);

  useEffect(() => {
    if (vacanciesData?.total > 0) {
      setPageCount(Math.ceil(vacanciesData?.total / queryParams.pageSize));
    }
  }, [vacanciesData]);

  useEffect(() => {
    setQueryParams({ ...queryParams, vacancyIds });
  }, [vacancyIds]);

  return (
    <>
      {(!isVacanciesLoading && !vacanciesData?.total) || !vacancyIds.length ? (
        <NoData />
      ) : (
        <div className={cx(style.CandidateCardVacancies)}>
          <table className={cx("hr-table-style")}>
            <colgroup>
              <col width={"25%"} />
              <col width={"13.5%"} />
              <col width={"16.9%"} />
              <col width={"32.6%"} />
              <col width={"12%"} />
            </colgroup>
            <thead>
              <tr>
                {tableHead.map((item, idx) => (
                  <th key={idx}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isVacanciesLoading ? (
                <SkeletonTable rows={queryParams.pageSize} />
              ) : (
                <>
                  {vacanciesData?.items.map((item: any, idx: number) => (
                    <tr
                      key={idx}
                      className="can-click"
                      onClick={() => {
                        window.open(
                          `${ROUTE_VACANCIES}/?vacancyId=${item.id}`,
                          "_blank",
                          "noreferrer",
                        );
                      }}
                    >
                      <td className="truncate-cell" title={item.title}>
                        {item.title}
                      </td>
                      <td
                        className="truncate-cell"
                        title={new Date(item.createDate).toLocaleDateString()}
                      >
                        {new Date(item.createDate).toLocaleDateString()}
                      </td>
                      <td>
                        <VacancyBadge badge={item} />
                      </td>
                      <td>
                        {candidate?.candidates.length > 0 && (
                          <CandidateBadge
                            badge={
                              candidate.candidates.filter(
                                (v: any) => v.vacancyId === item.id,
                              )[0]
                            }
                          />
                        )}
                      </td>
                      <td className="text-right">
                        <Button
                          className={cx("mr-4", "bg-content-2", "text-black")}
                          iconLeft={<GlyphSwap />}
                          onClick={(e?: React.MouseEvent) => {
                            e?.preventDefault();
                            e?.stopPropagation();
                            setSelectedVacancy(item.id);
                            setSelectedCandidate(candidate.id);
                            setIsModalStatusEditOpen(true);
                          }}
                          variant="secondary"
                        ></Button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          {pageCount > 1 && (
            <Pagination
              className="pl-0 pagination"
              currentNumber={queryParams.pageNum}
              onChange={function ka(page: any) {
                setQueryParams({ ...queryParams, pageNum: page });
              }}
              total={pageCount}
            />
          )}
        </div>
      )}

      {isModalStatusEditOpen ? (
        <ChangeStatusPopup
          candidate={{ id: selectedCandidate }}
          clickOnClose={(needUpdateList = false) => {
            setIsModalStatusEditOpen(false);

            if (needUpdateList) {
              updateCandidateData({
                candidateId: candidate.id,
                rnd: generateRandomIdByLength(10),
              });
            }
          }}
          vacancy={{ id: selectedVacancy }}
        />
      ) : null}
    </>
  );
};

export default CardVacancies;
