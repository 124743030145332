import { API_AUTH_REFRESH } from "./constants";
import { AxiosError, AxiosRequestConfig } from "@aurora/services";
import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import Cookies from "js-cookie";
import queryString from "query-string";

export const request: any = async ({
  count = 1,
  data,
  headers = {},
  method,
  params,
  responseType,
  url,
  withCredentials,
}: any) => {
  try {
    headers = {
      ...headers,
      Authorization: `Bearer ${Cookies.get("accessToken")}`,
    };

    const result = await axios({
      data,
      headers: headers,
      method: method || "get",
      params,
      paramsSerializer: (params: Record<string, unknown>) => {
        return queryString.stringify(params, {
          arrayFormat: "none",
          skipEmptyString: true,
          skipNull: true,
        });
      },
      responseType,
      url,
      withCredentials: withCredentials || false,
    });
    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;

    if (err.response?.status === 401 && count <= 3) {
      // Ошибка авторизации
      // Пробуем обновить токен
      await axios({
        data: null,
        headers,
        method: "get",
        params: null,
        responseType,
        url: API_AUTH_REFRESH,
        withCredentials: true,
      });

      return await request({
        count: count + 1,
        data,
        headers,
        method,
        params,
        responseType,
        url,
        withCredentials,
      });
    } else if (
      (err.response?.status === 401 && count === 3) ||
      err.response?.status === 500
    ) {
      location.href = "/";
    }

    return {
      error: {
        data: err.response?.data || err.message,
        status: err.response?.status || err.status,
      },
    };
  }
};

export const axiosBaseQuery = (
  { baseUrl }: { baseUrl: string } = { baseUrl: "" },
): BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    responseType?: AxiosRequestConfig["responseType"];
    headers?: AxiosRequestConfig["headers"];
    withCredentials?: AxiosRequestConfig["withCredentials"];
  },
  unknown,
  unknown
> => request;

export const api = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: "" }),
  endpoints: () => ({}),
  reducerPath: "api",
});
