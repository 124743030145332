export function checkEmail(text: string, ignoreEmpty = true) {
  if (ignoreEmpty && !text) return true;
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  return emailPattern.test(text);
}

export function checkUsername(text: string, ignoreEmpty = true) {
  if (ignoreEmpty && !text) return true;

  const usernamePattern = /^[a-zA-Z0-9]{5,10}$/;

  return usernamePattern.test(text);
}

export function validateName(text: string) {
  if (text.charAt(0) === "-") {
    text = text.slice(1);
  }

  text = text.replace(/[^a-zA-Zа-яА-ЯёЁ-]/g, "");
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function checkPhone(text: string, ignoreEmpty = true) {
  if (ignoreEmpty && !text) return true;
  text = text.replace(/[^\d]/g, "");
  const phoneNumberPattern = /^[7-8]9[0-9 \(\)\-]+$/;

  return phoneNumberPattern.test(text) && text.length === 11;
}
