import { ROUTE_ROOT } from "constants/routes";
import { useLogoutQuery } from "core/api/logout/logout";
import { store } from "core/store";
import { setAuth } from "core/store/reducers/AuthSlice";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Logout: FC = () => {
  const navigate = useNavigate();

  const {
    data: isLogout,
    error: vacanciesError,
    isLoading: isLogoutLoading,
  } = useLogoutQuery();

  function logout() {
    setTimeout(() => {
      store.dispatch(setAuth(false));
      navigate(ROUTE_ROOT);
    }, 0);
  }
  useEffect(() => {
    logout();
  }, [isLogoutLoading]);

  return <></>;
};
export default Logout;
