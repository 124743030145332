import Filters from "./Filters/Filters";
import List from "./List/List";
import style from "./Statistics.module.scss";
import { reportTypes } from "./constants";
import cx from "classnames";
import { vacationCustomApi } from "core/api/vacancy/vacancy";
import { FC, useState } from "react";

const Statistics: FC = () => {
  const [file, setFileUploader] = useState(null);
  const [type, changeType]: any = useState(null);

  const [isLoading, changeIsLoading]: any = useState(false);

  return (
    <>
      <div className={cx(style.Statistics)}>
        <div>
          <h1 className={cx("my-0 text-black")}>Статистика</h1>
        </div>
        <div className="hr-content">
          <div className="hr-content-left">
            <List file={file} isLoading={isLoading} type={type}></List>
          </div>
          <div className="hr-content-right">
            <Filters
              changeTypeReport={changeType}
              isLoading={isLoading}
              onGetReport={(params: any) => {
                const reportsByType = {
                  [reportTypes.VACANCY]:
                    vacationCustomApi.generateReportVacancy,
                  [reportTypes.CATEGORY]:
                    vacationCustomApi.generateReportVacancyCategory,
                };
                setFileUploader(null);
                changeIsLoading(true);
                reportsByType[type](params).then((resp: any) => {
                  const fileNames = {
                    [reportTypes.VACANCY]: "Статистика по вакансиям",
                    [reportTypes.CATEGORY]: "Статистика по категориям",
                  };

                  let fileName = fileNames[type];

                  if (params.createPeriodStart && params.createPeriodEnd) {
                    fileName += ` ${params.createPeriodStart}-${params.createPeriodEnd}`;
                  }

                  const generateFile: any = new File(
                    [resp.data],
                    `${fileName}.xlsx`,
                  );
                  setFileUploader(generateFile);
                  changeIsLoading(false);
                });
              }}
            ></Filters>
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
