import style from "../CandidateBadge/CandidateBadge.module.scss";
import { getCategoryString } from "../CandidateBadge/helpers";
import { Badge } from "@aurora/components";
import cx from "classnames";

const VacancyBadge: React.FC<Props> = ({ badge }) => {
  if (!badge || !badge.stateCategoryCode) {
    return null;
  }

  return (
    <div title={badge.stateTitle}>
      <Badge
        className={cx(style.CandidateBadge, "truncate-cell-percent")}
        color={getCategoryString(badge.stateCategoryCode)}
        label={badge.stateTitle}
        size={"m28"}
        theme={"light"}
        variant={"fh-v2"}
      />
    </div>
  );
};

export default VacancyBadge;
