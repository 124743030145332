import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const storedIsAuth = Cookies.get("isAuth");

interface AuthState {
  isAuth: boolean;
  isLoading: boolean;
}

const initialState: AuthState = { isAuth: !!storedIsAuth, isLoading: false };

export const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    setAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setAuth, setLoading } = authSlice.actions;
export default authSlice.reducer;
