import { useVacancyHistoryQuery } from "../../../../core/api/vacancy/vacancy";
import { dateToRu } from "../../../../services/date.service";
import NoData from "../../../BaseComponents/NoData";
import { SkeletonTable } from "../../../BaseComponents/SkeletonTable/SkeletonTable";
import styles from "../VacancionCard.module.scss";
import { AvatarItem } from "@aurora/components";
import { FC } from "react";

interface Props {
  vacancyId: string;
  stateCode: string;
}
const History: FC<Props> = ({ stateCode, vacancyId }) => {
  if (!vacancyId) {
    return <NoData />;
  }

  const { data: vacancyHistory, isLoading: isLoadingVacancyHistory } =
    useVacancyHistoryQuery(
      { stateCode, vacancyId },
      { refetchOnMountOrArgChange: true },
    );

  return (
    <>
      <div className={styles.VacancyHistory}>
        <table>
          <colgroup>
            <col width={"12%"} />
            <col width={"35%"} />
            <col width={"30%"} />
            <col width={"23%"} />
          </colgroup>
          <thead>
            <tr>
              <th>Дата</th>
              <th>
                Описание
                <br />
                события
              </th>
              <th>Комментарий</th>
              <th>Пользователь</th>
            </tr>
          </thead>
          <tbody>
            {!isLoadingVacancyHistory && Array.isArray(vacancyHistory) ? (
              vacancyHistory.map((item: any) => {
                return (
                  <tr key={item.id} className={"py-8"}>
                    <td>{dateToRu(item.createDateTime)}</td>
                    <td>
                      {item.stateTitleFrom && item.stateTitleTo
                        ? `Перевод статуса: Из "${item.stateTitleFrom}" в "${item.stateTitleTo}"`
                        : `${item.stateTitleTo}`}
                    </td>
                    <td className="truncate-cell" title={item.comment}>
                      {item.comment || "-"}
                    </td>
                    <td>
                      <AvatarItem caption={String(item.author)} />
                    </td>
                  </tr>
                );
              })
            ) : (
              <SkeletonTable rows={10} />
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default History;
