import style from "./Modal.module.scss";
import SkeletonField from "./Skeleton/Skeleton";
import {
  Button,
  Popup,
  Select,
  useSnackbars,
  Tabs,
  Tab,
  Checkbox,
} from "@aurora/components";
import cx from "classnames";
import NoData from "components/BaseComponents/NoData";
import { DICTIONARY } from "constants/dictionary";
import {
  useCandidatesListQuery,
  usePersonBindToVacancyMutation,
  usePersonUnbindFromVacancyMutation,
} from "core/api/candidate/candidate";
import { useVacanciesListQuery } from "core/api/vacancy/vacancy";
import { FC, useEffect, useState } from "react";
import { snackbarFail, snackbarSuccess } from "services/snackbar.service";

interface IVacanciesContext {
  type: "vacancy" | "candidate";
  clickOnClose: any;
  candidate?: any;
  vacancy?: any;
}

const activeTabValues = {
  BIND: 0,
  UNBIND: 1,
};

const LinkWithVacancy: FC<IVacanciesContext> = ({
  candidate,
  clickOnClose,
  type,
  vacancy,
}) => {
  const { snackbarInfo } = useSnackbars();
  const [activeTab, changeActiveTab] = useState(0);
  const [selectedVacationForUnbind, changeUnbindVacation]: any = useState({});

  const [selectedItem, changeSelectedItem]: any = useState(null);
  const [isLoading, changeStatusLoading]: any = useState(true);
  const [saveDisabled, changeDisabled]: any = useState(true);

  const [queryVacationParams] = useState({
    categoryIds: [],
    createPeriodEnd: "",
    createPeriodStart: "",
    managerIds: [],
    pageNum: 1,
    pageSize: 99999,
    statusIds: [],
    vacancyTitle: [],
    vacancyTitles: [],
  });

  const [queryCandidateParams] = useState({
    pageNum: 1,
    pageSize: 99999,
  });

  const {
    data: vacanciesData,
    error: vacanciesError,
    isLoading: isVacanciesLoading,
  } = useVacanciesListQuery(queryVacationParams, { skip: type === "vacancy" });

  const {
    data: candidatesData,
    error: candidateError,
    isLoading: isCandidatesLoading,
  } = useCandidatesListQuery(queryCandidateParams, {
    skip: type === "candidate",
  });

  const [bindPersonToVacancy] = usePersonBindToVacancyMutation();
  const [unbindPersonFromVacancy] = usePersonUnbindFromVacancyMutation();
  // отображение ошибки в консоли
  useEffect(() => {
    if (vacanciesError) console.error("vacanciesError", vacanciesError);
    if (candidateError) console.error("candidateError", candidateError);
  }, [vacanciesError, candidateError]);

  function showNotificationOfResult(hasError: boolean, data?: any) {
    if (hasError) {
      const fail: any = snackbarFail(DICTIONARY.FAIL);
      snackbarInfo?.show(fail);
      return;
    }

    if (data) {
      snackbarInfo?.show(
        snackbarSuccess(
          activeTab === activeTabValues.BIND
            ? DICTIONARY.BIND_CANDIDATE_VACANCY(
                data.candidateName,
                data.vacancyName,
              )
            : DICTIONARY.UNBIND_CANDIDATE_VACANCY(
                data.candidateName,
                data.vacancyName,
              ),
        ),
      );
    }
  }

  function getActiveVacationUnbind() {
    return Object.keys(selectedVacationForUnbind)
      .filter((vacancyId: string) => {
        return selectedVacationForUnbind[vacancyId];
      })
      .map((vacancyId: string) => {
        const activeVacancy = candidate?.vacancies.filter((vacancy: any) => {
          return vacancyId === vacancy.vacancyId;
        })[0];
        return activeVacancy;
      });
  }

  function checkIfDisabledSaveBtn() {
    let isDisabled = true;
    if (activeTab === activeTabValues.BIND || type === "vacancy") {
      isDisabled = !selectedItem;
    }

    if (activeTab === activeTabValues.UNBIND) {
      isDisabled = !getActiveVacationUnbind().length;
    }

    changeDisabled(isDisabled);
  }

  useEffect(() => {
    if (isVacanciesLoading || isCandidatesLoading) return;
    changeStatusLoading(false);
  }, [isVacanciesLoading, isCandidatesLoading]);

  useEffect(() => {
    checkIfDisabledSaveBtn();
  }, [selectedItem, selectedVacationForUnbind, activeTab]);

  const VACANCY_CONTENT = (
    <>
      <p className="my-16 w-75">
        Вы можете связать карточку кандидата к данной вакансий
      </p>
      <div>
        <Select
          className={cx("p-0")}
          data={candidatesData?.items || []}
          label="Выберите кандидата"
          onChange={changeSelectedItem}
          placeholder="Выберите из списка"
          shape="autocomplite"
          value={selectedItem}
        />
      </div>
    </>
  );

  const CANDIDATE_CONTENT = (
    <>
      <Tabs
        onChange={(v, value) => {
          changeActiveTab(value);
        }}
        value={activeTab}
        variant={"primary"}
      >
        <Tab label="Связать" value={0}></Tab>
        <Tab label="Отвязать" value={1}></Tab>
      </Tabs>

      {activeTab === activeTabValues.BIND && (
        <>
          <p className="my-16 w-75">
            Вы можете связать карточку данного кандидата к какой-либо из
            созданных вакансий
          </p>
          <div>
            <Select
              className={cx("p-0")}
              data={vacanciesData?.items || []}
              label="Выберите вакансию"
              onChange={changeSelectedItem}
              placeholder="Выберите из списка"
              shape="autocomplite"
              value={selectedItem}
            />
          </div>
        </>
      )}

      {activeTab === activeTabValues.UNBIND && (
        <>
          <p className="my-16 w-75">
            Вы можете отвязать карточку данного кандидата от вакансий, с
            которыми она связана
          </p>

          <div className={style.vacancies}>
            {candidate?.vacancies.map((vacancy: any, idx: number) => {
              return (
                <div key={idx} className="mb-8">
                  <Checkbox
                    checked={selectedVacationForUnbind[vacancy.vacancyId]}
                    description={vacancy.candidateStateTitle}
                    label={vacancy.vacancyTitle}
                    onChange={() => {
                      const nObj = Object.assign(selectedVacationForUnbind, {
                        [vacancy.vacancyId]:
                          !selectedVacationForUnbind[vacancy.vacancyId],
                      });
                      changeUnbindVacation(Object.assign({}, nObj));
                    }}
                  />
                </div>
              );
            })}
            {!candidate?.vacancies.length ? <NoData /> : null}
          </div>
        </>
      )}
    </>
  );

  return (
    <>
      <Popup
        classNameContent={cx(style.modal, "p-16")}
        classNameWrapper="p-0"
        onClickCloseCross={clickOnClose}
        onClickOutside={clickOnClose}
      >
        {isLoading ? (
          <SkeletonField />
        ) : (
          <>
            <div className={"title-and-buttons-align"}>
              <h1 className={cx("my-0 text-black")}>
                {type === "vacancy"
                  ? "Добавить кандидата"
                  : "Связи с вакансиями"}
              </h1>
              <div>
                <Button onClick={clickOnClose} variant="secondary">
                  Отмена
                </Button>
                <Button
                  disabled={saveDisabled}
                  onClick={async () => {
                    if (
                      activeTab === activeTabValues.BIND ||
                      type === "vacancy"
                    ) {
                      let personId = null;
                      let vacancyId = null;

                      let candidateName = null;
                      let vacancyName = null;

                      if (type === "vacancy") {
                        personId = selectedItem.id;
                        vacancyId = vacancy.id;

                        candidateName = selectedItem.value;
                        vacancyName = vacancy.title;
                      }

                      if (type === "candidate") {
                        personId = candidate.id;
                        vacancyId = selectedItem.id;

                        candidateName = candidate.fullName;
                        vacancyName = selectedItem.value;
                      }

                      const resultOfBind: any = await bindPersonToVacancy({
                        personId,
                        vacancyId,
                      });

                      const hasError = !!resultOfBind.error;
                      showNotificationOfResult(hasError, {
                        candidateName,
                        vacancyName,
                      });
                      if (!hasError) clickOnClose();
                    }

                    if (activeTab === activeTabValues.UNBIND) {
                      const personId = candidate.id;

                      Promise.all(
                        getActiveVacationUnbind().map((vacancy, idx) => {
                          return new Promise((resolver: any) => {
                            setTimeout(() => {
                              // Need for fix queue at
                              unbindPersonFromVacancy({
                                personId,
                                vacancyId: vacancy.vacancyId,
                              }).then((result: any) => {
                                resolver(Object.assign(result, { vacancy }));
                              });
                            }, 200 * idx);
                          });
                        }),
                      ).then((values: any) => {
                        const successUnbindNames = values
                          .map((result: any) => {
                            if (result.error) {
                              showNotificationOfResult(true);
                              return "";
                            }

                            return result.vacancy.vacancyTitle;
                          })
                          .join(",");

                        successUnbindNames.length &&
                          showNotificationOfResult(false, {
                            candidateName: candidate.fullName,
                            vacancyName: successUnbindNames,
                          });
                        if (successUnbindNames.length) clickOnClose();
                      });
                    }
                  }}
                  variant="primary"
                >
                  Сохранить
                </Button>
              </div>
            </div>
            {type === "vacancy" ? VACANCY_CONTENT : CANDIDATE_CONTENT}
          </>
        )}
      </Popup>
    </>
  );
};
export default LinkWithVacancy;
