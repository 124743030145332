import { Skeleton } from "@aurora/components";
import cx from "classnames";
import { FC } from "react";

interface iSkeleton {
  count?: number;
}
const SkeletonField: FC<iSkeleton> = ({ count = 2 }) => {
  return (
    <>
      {new Array(count).fill(null).map((i, idx) => {
        return (
          <div key={idx}>
            <div className={cx("mt-16", "skeletons-flex-field")}>
              <div>
                <Skeleton
                  height={16}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
              <div>
                <Skeleton
                  height={16}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
              <div>
                <Skeleton
                  height={16}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
            </div>

            <div className={cx("mt-16", "skeletons-flex-field")}>
              <div>
                <Skeleton
                  height={32}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
              <div>
                <Skeleton
                  height={32}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export const SkeletonOneLine = () => {
  const size = 46;
  return (
    <div className={cx("mb-20", "skeletons-flex-field")}>
      <div>
        <Skeleton height={size} radius="r5" shape="line" variant="primary" />
      </div>
      <div>
        <Skeleton height={size} radius="r5" shape="line" variant="primary" />
      </div>
      <div>
        <Skeleton height={size} radius="r5" shape="line" variant="primary" />
      </div>
    </div>
  );
};

export default SkeletonField;
