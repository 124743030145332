import { CircleLoader } from "@aurora/components";
import Content from "components/AuthComponents/Content/Content";
import Auth from "components/NotAuthComponents/Auth";
import { ROUTE_AUTH } from "constants/routes";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

const ContainerRouter = () => {
  const isAuth = useSelector((store: any) => store.authSlice.isAuth);
  const isLoading = useSelector((store: any) => store.authSlice.isLoading);

  if (isLoading) {
    return <CircleLoader brandColor type={"page"} />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<CircleLoader brandColor type={"page"} />}>
        <Routes>
          {isAuth ? (
            // Авторизованная зона
            <>
              <Route element={<Content />} path="*" />
            </>
          ) : (
            // Неавторизованная зона
            <>
              <Route element={<Navigate to={ROUTE_AUTH} />} path="/" />
              <Route element={<Auth />} path={ROUTE_AUTH} />
              <Route element={<Auth />} path="*" />
            </>
          )}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default ContainerRouter;
