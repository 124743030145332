import { Input } from "@aurora/components";
import { FC } from "react";

interface Props {
  candidatesData?: any;
}
const FirstMessage: FC<Props> = () => {
  return (
    <>
      <p className="w-75">
        Всем выбранным кандидатам будет отправлено сообщение ниже. Вы можете
        скорректировать его
      </p>
      <Input
        className={"py-8 px-6"}
        placeholder="Тут текст первого приветственного сообщения. Привет! Хотим с вами поговорить о вакансии"
      />
    </>
  );
};

export default FirstMessage;
