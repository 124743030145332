import { DICTIONARY } from "../constants/dictionary";
import { useSnackbars } from "@aurora/components";
import { snackbarForbidden, snackbarFail } from "services/snackbar.service";

const useErrorNotification = () => {
  const { snackbarInfo } = useSnackbars();
  const apiErrHandler = (error: any) => {
    let info: any;
    if (error.status > 499) {
      info = snackbarFail(DICTIONARY.FAIL);
    } else if (error.status === 403) {
      info = snackbarForbidden(DICTIONARY.FORBIDDEN);
    } else if (error.status === 409) {
      info = snackbarForbidden(DICTIONARY.CONFLICT_PERSON);
    } else {
      info = snackbarForbidden(DICTIONARY.UNKNOWN_ERR);
    }
    snackbarInfo?.show(info);
  };
  return apiErrHandler;
};

export default useErrorNotification;
