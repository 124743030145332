import Label from "../../../BaseComponents/Label";
import style from "./VacancionCardInfo.module.scss";
import {
  InformationCell,
  MultiselectData,
  Skeleton,
  Text,
  UploadedFile,
} from "@aurora/components";
import { getFormattedFileSize } from "@aurora/components/dist/FileUploader/helpers";
import cx from "classnames";
import {
  useVacationCategoriesQuery,
  useVacationGetFileInfoMutation,
  vacationCustomApi,
} from "core/api/vacancy/vacancy";
import { FC, useEffect, useState } from "react";

interface Props {
  data?: any;
}

const VacationCardInfo: FC<Props> = ({ data }: any) => {
  const [fileVacancy, changeFileVacancy]: any = useState<File | null>();
  const [fileVacancyInfo, changeFileInfo]: any = useState(null);
  const [labels, setLabels] = useState<MultiselectData[]>([]);

  const { data: CategoriesData, error: CategoriesError } =
    useVacationCategoriesQuery();

  const [getFileInfo] = useVacationGetFileInfoMutation();

  useEffect(() => {
    if (data?.labels.length > 0) {
      const labelsMap: MultiselectData[] = [...data.labels];
      setLabels(labelsMap);
    }
  }, [data]);
  // отображение ошибки в консоли
  useEffect(() => {
    if (CategoriesError) console.error("CategoriesError", CategoriesError);
  }, [CategoriesError]);

  useEffect(() => {
    if (!data.fileUploaded) return;
    Promise.all([
      vacationCustomApi.downloadFile({ vacancyId: data.id }),
      getFileInfo({ vacancyId: data.id }),
    ]).then(values => {
      const file = values[0];
      const info: any = values[1];
      const vacancyFile = new File([file.data], info.data.fileName);
      changeFileInfo(info.data);
      changeFileVacancy(vacancyFile);
    });
  }, []);

  function openPdfInNewTab() {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(fileVacancy);
    link.download = fileVacancyInfo.fileName;
    link.dispatchEvent(new MouseEvent("click"));
  }

  const currentCategory = CategoriesData.filter(
    (cat: any) => cat.id === data.categoryId,
  )[0];

  const vacancyData = [
    {
      label: "Название вакансии",
      value: data.title,
    },
    {
      label: "Ссылка на вакансию",
      value: data.link ? (
        <a
          className={cx("link")}
          href={data.link}
          rel="noreferrer"
          target="_blank"
        >
          Перейти
        </a>
      ) : null,
    },
    {
      label: "Категория",
      value: currentCategory.value,
    },
    {
      label: "Контакты нанимающего менеджера",
      value: data.managerContacts,
    },
    {
      label: "Дата создания",
      value: data.createDate,
    },
    {
      label: "Дней в работе",
      value: String(data.inProgressDays) || "-",
    },
    {
      label: "Локация",
      value: data.location,
    },
    {
      label: "Нанимающий менеджер",
      value: data.managerFullName || "-",
    },
  ];

  //если данных нет, то не отображаем соответствующий блок
  const filteredVacancyData = vacancyData.filter(item => item.value);

  return (
    <div>
      <div className={cx("border-bottom pb-16")}>
        <div className={style.VacationCardInfo}>
          {filteredVacancyData.map((item, idx) => (
            <InformationCell
              key={idx}
              label={
                <Text tag="div" type="labelRegular">
                  {item.label}
                </Text>
              }
              separator={false}
              title={item.value}
            />
          ))}
        </div>
      </div>

      {data.requirements ? (
        <div className="mt-16">
          <InformationCell
            label={
              <Text tag="div" type="labelRegular">
                Требования к кандидату
              </Text>
            }
            separator={false}
            title={<div className="formatted-text">{data.requirements}</div>}
          />
        </div>
      ) : null}

      {data.responsibilities ? (
        <div>
          <InformationCell
            label={
              <Text tag="div" type="labelRegular">
                Задачи вакансии
              </Text>
            }
            separator={false}
            title={
              <div className="formatted-text">{data.responsibilities}</div>
            }
          />
        </div>
      ) : null}

      {data.description ? (
        <div>
          <InformationCell
            label={
              <Text tag="div" type="labelRegular">
                Описание вакансии для шаблона-приглашения
              </Text>
            }
            separator={false}
            title={<span className="formatted-text">{data.description}</span>}
          />
        </div>
      ) : null}

      {data.options ? (
        <div className="pb-16 border-bottom">
          <InformationCell
            label={
              <Text tag="div" type="labelRegular">
                Условия вакансии
              </Text>
            }
            separator={false}
            title={<div className="formatted-text">{data.options}</div>}
          />
        </div>
      ) : null}
      {data.id ? (
        <Label
          entity={"vacancy"}
          initLabels={labels}
          needConfirmed={true}
          needSaveBt={true}
          onLabelsChange={setLabels}
          vacancyId={data.id}
        />
      ) : null}
      {data.fileUploaded ? (
        <div className="pt-16 border-top">
          <InformationCell
            label={
              <Text tag="div" type="labelRegular">
                Загруженные файлы
              </Text>
            }
            separator={false}
            title=""
          />

          {fileVacancy ? (
            <a
              className={"text-decoration-none"}
              onClick={() => {
                openPdfInNewTab();
              }}
            >
              {" "}
              <UploadedFile
                description={getFormattedFileSize(fileVacancy.size)}
                errorDescription="error description"
                file={fileVacancy}
                indent={false}
                loadingDescription="loading"
                shape="cardCell"
                shortDescription="short"
                showDeleteButton={false}
                status="FILE_SUCCESS"
                title="Файл вакансии"
              />
            </a>
          ) : (
            <div>
              <Skeleton
                height={64}
                radius="r5"
                shape="line"
                variant="primary"
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default VacationCardInfo;
