import { reportTypes } from "../constants";
import { UploadedFile } from "@aurora/components";
import { getFormattedFileSize } from "@aurora/components/dist/FileUploader/helpers";
import cx from "classnames";
import { FC, useEffect, useState } from "react";

interface State {
  type: any;
  file: any;
  isLoading: boolean;
}

const List: FC<State> = ({ file, isLoading, type }) => {
  const [helperText, changeText] = useState("");

  useEffect(() => {
    if (isLoading) {
      changeText("Отчет формируется, пожалуйста подождите..");
      return;
    }

    switch (type) {
      case reportTypes.VACANCY:
      case reportTypes.CATEGORY:
        changeText(
          "Выберите необходимые условия и нажмите «Сформировать отчёт»",
        );
        break;
      default: {
        changeText("Для формирования отчёта выберите тип в окне справа");
      }
    }
  }, [type, isLoading]);

  return (
    <>
      {/* Отчёт сформирован! */}
      <div className="text-center text-accent py-20">
        <h2>{file ? "Отчёт сформирован!" : "Подсказка"}</h2>
        <p className="text-black">
          {file
            ? "Сохраните его или измените параметры для формирования нового"
            : helperText}
        </p>
      </div>
      {file ? (
        <>
          <div>
            <div
              className={cx("mt-4")}
              onClick={() => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(file);

                link.download = `${file.name}`;
                link.dispatchEvent(new MouseEvent("click"));
              }}
            >
              <UploadedFile
                description={getFormattedFileSize(file)}
                errorDescription="error description"
                file={file}
                indent={false}
                loadingDescription="loading"
                shape="cardCell"
                shortDescription="short"
                showDeleteButton={false}
                status="FILE_SUCCESS"
                title={`${file.name}`}
              />
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default List;
