import { ICardDialog } from "../types";
import styles from "./Card.module.scss";
import { Button, Card, Select, Skeleton, TextArea } from "@aurora/components";
import { GlyphChevronRight } from "@aurora/icons";
import cx from "classnames";
import {
  useAddChatMutation,
  useGetExternalChatListMutation,
  useGetMessagesMutation,
  useSendMessageMutation,
} from "core/api/chat/chat";
import { useGetUserInfoMutation } from "core/api/user/user";
import { useVacanciesListQuery } from "core/api/vacancy/vacancy";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  convertUtcToLocal,
  getCurrentMonthAndDay,
} from "services/date.service";
import { generateRandomIdByLength } from "services/generate.service";

const AVAILABLE_TYPES = {
  CHAT: "chat",
  CREATE: "create",
  LOADING: "loading",
  START: "start",
};
const AVAILABLE_STATUSES: any = {
  DELIVERED: "Доставлено",
  FAILED: "Не доставлено",
  LOOKED: "Прочитано",
  RECEIVED: "Отправлено",
};

const CardDialog: FC<ICardDialog> = ({ personId, phone, vacancyIds }) => {
  const [dialogType, changeDialogType] = useState(AVAILABLE_TYPES.LOADING);
  const [userMessage, setUserMessageText] = useState("");
  const [isLoadingMsgSend, changeTypeLoading] = useState(false);
  const [selectedItem, changeSelectedItem]: any = useState(null);
  const [isShiftPressed, changeShiftPressed]: any = useState(false);
  const [loadUserData] = useGetUserInfoMutation();
  const [userData, changeUserData]: any = useState({});

  const [isChatFieldHasFocus, changeFocusState]: any = useState(false);
  const [disabledStartChatButton, changeDisabledOfStartChatButton] =
    useState(false);
  const [messages, setMessages]: any = useState([]);

  const [createChat] = useAddChatMutation();
  const [getMessages] = useGetMessagesMutation();
  const [sendMessage] = useSendMessageMutation();
  const [loadChatInfo] = useGetExternalChatListMutation();

  const [queryParams, setQueryParams] = useState({
    pageNum: 1,
    pageSize: 999,
    rnd: generateRandomIdByLength(10),
    vacancyIds,
  });

  const {
    data: vacanciesData,
    error: vacanciesError,
    isLoading: isVacanciesLoading,
  } = useVacanciesListQuery(queryParams, {
    skip: queryParams.vacancyIds?.length === 0,
  });

  useEffect(() => {
    if (vacanciesError) console.error("vacanciesError", vacanciesError);
  }, [vacanciesError]);

  useEffect(() => {
    if (isVacanciesLoading) return;
    if (vacanciesData?.items.length) {
      changeSelectedItem(vacanciesData?.items[0]);
    }

    let intervalMessages: any = null;
    getChatInfo(personId).then(async (chatInfo: any) => {
      if (chatInfo?.id) {
        await loadMessages(chatInfo.id);
        intervalMessages = setInterval(() => {
          loadMessages(chatInfo.id);
        }, 5000);
      } else {
        changeDialogType(AVAILABLE_TYPES.START);
      }
    });

    return () => {
      clearInterval(intervalMessages);
    };
  }, [isVacanciesLoading, dialogType]);

  useEffect(() => {
    loadUserData().then((data: any) => {
      changeUserData(data.data);
    });
  }, []);

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      vacancyIds,
    });
  }, [vacancyIds]);

  function getFormattedMessages(messages: any) {
    if (!messages || messages.length === 0) return [];
    let prevItem: any = null;
    const idxOfMessages: any = {};

    const dialogList = messages.reduce((acc: any, item: any, idx: number) => {
      const author = item.author;
      const status = item.status;
      const cloneItem = Object.assign(
        {
          currentDay: getCurrentMonthAndDay(new Date(item.time)),
          formattedTime: convertUtcToLocal(new Date(item.time)),
        },
        item,
      );

      if (!(cloneItem.currentDay in idxOfMessages)) {
        idxOfMessages[cloneItem.currentDay] = idx;
      }

      const idxOfCurrentDay = `${idxOfMessages[cloneItem.currentDay]}|${
        cloneItem.currentDay
      }`;

      if (!acc[idxOfCurrentDay]) {
        acc[idxOfCurrentDay] = [];
      }

      const messagesGroupCurrent =
        acc[idxOfCurrentDay][acc[idxOfCurrentDay].length - 1];

      if (
        prevItem &&
        author === prevItem.author &&
        status === prevItem.status &&
        messagesGroupCurrent
      ) {
        messagesGroupCurrent.unshift(cloneItem);
      } else {
        acc[idxOfCurrentDay].push([cloneItem]);
      }

      prevItem = cloneItem;
      return acc;
    }, {});

    return dialogList;
  }

  async function getChatInfo(personId: string) {
    const chatInfo: any = await loadChatInfo({ participantId: personId });

    return chatInfo?.data[0];
  }

  async function loadMessages(chatId: string) {
    return getMessages(chatId).then((messages: any) => {
      setMessages(getFormattedMessages(messages.data));

      if (dialogType !== AVAILABLE_TYPES.CHAT) {
        changeDialogType(AVAILABLE_TYPES.CHAT);
      }
    });
  }

  async function createRequestAndSendMessage() {
    if (isLoadingMsgSend) return;
    changeFocusState(false);
    changeTypeLoading(true);
    const chatInfo: any = await getChatInfo(personId);
    const text = userMessage;
    const msgResult: any = await sendMessage({
      chatId: chatInfo.id,
      senderId: userData.username,
      senderName: userData.username,
      text,
      type: "TEXT",
    });
    changeTypeLoading(false);
    if (!msgResult.error) {
      setUserMessageText("");
    }
    await loadMessages(chatInfo.id);
  }

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && userMessage.trim() !== "" && !isShiftPressed) {
      // Создание нового массива сообщений с добавленным новым сообщением
      createRequestAndSendMessage();
    }
  };

  const NO_MESSAGES = (
    <div className={styles.CandidateCardDialogMessage}>
      <h1 className="text-accent text-center mb-12">Сообщений нет</h1>
      <p className={cx("mb-20 mt-0", styles.CandidateCardDialogMessageHelper)}>
        Начните диалог и отправьте первое сообщение кандидату, чтобы начать
        общение
      </p>
    </div>
  );

  return (
    <div className={cx(styles.CandidateCardDialog, "pt-8")}>
      {dialogType === AVAILABLE_TYPES.LOADING ? (
        <>
          {new Array(10).fill(null).map((i, idx: number) => {
            return (
              <Skeleton
                key={idx}
                className="mb-8"
                height={48}
                radius="r2"
                shape="line"
                variant="primary"
              />
            );
          })}
        </>
      ) : null}

      {dialogType === AVAILABLE_TYPES.START ? (
        <>
          <div className={styles.CandidateCardDialogMessage}>
            <h1 className="text-accent text-center mb-12">Новый диалог</h1>
            <p
              className={cx(
                "mb-12 mt-0",
                styles.CandidateCardDialogMessageHelper,
              )}
            >
              Вы можете начать диалог с данным кандидатом в любое время. После
              нажатия кнопки «Начать диалог» кандидату уйдет приветственное
              сообщение в WhatsApp по согласованному шаблону. Для перехода в
              личный WhatsApp нажмите на «Перейти в WhatsApp».
            </p>
            {vacanciesData?.items.length > 1 ? (
              <Select
                className={cx("p-0")}
                data={vacanciesData?.items || []}
                label="Выберите вакансию"
                onChange={changeSelectedItem}
                placeholder="Выберите из списка"
                shape="autocomplite"
                value={selectedItem}
              />
            ) : null}

            <Button
              className="mt-8"
              disabled={
                !vacancyIds?.length || disabledStartChatButton || !phone
              }
              onClick={async () => {
                changeDisabledOfStartChatButton(true);
                const chat: any = await createChat({
                  id: "",
                  participants: [
                    {
                      communication: {
                        account: localStorage.getItem("phone"),
                        method: "WHATSAPP",
                      },
                      id: personId,
                      type: "EXTERNAL",
                    },
                  ],
                });

                await sendMessage({
                  additionalParams: {
                    vacancyId: selectedItem.id,
                  },
                  chatId: chat?.data?.id,
                  senderId: userData.username,
                  senderName: userData.username,
                  template: "welcome_for_interview",
                  type: "TEMPLATE",
                }).then(() => {
                  loadMessages(chat?.data?.id);
                });
              }}
            >
              Начать диалог
            </Button>
            {phone ? (
              <Link
                target="_blank"
                to={`//api.whatsapp.com/send?phone=${phone.replace(
                  /[^\d]/g,
                  "",
                )}`}
              >
                <Button
                  className="mt-4"
                  onClick={() => {
                    //
                  }}
                  variant="secondary"
                >
                  Перейти в WhatsApp
                </Button>
              </Link>
            ) : null}
          </div>
        </>
      ) : null}

      {dialogType === AVAILABLE_TYPES.CHAT ? (
        <div className={styles.CandidateCardCreateChat}>
          <div className={cx(styles.CandidateCardChat, "pb-8")}>
            {Object.keys(messages).map((day: string, keyOfDay: number) => {
              return (
                <>
                  {messages[day].map((group: any, key: number) => {
                    return (
                      <>
                        <div
                          key={"" + key + keyOfDay}
                          className={cx(
                            group.length > 1 ? styles.CandidateCardGroup : null,
                            "my-12",
                          )}
                        >
                          {group.map((msg: any, key: number) => {
                            const isSender = msg.author === userData.username;
                            const isLastItem = group.length - 1 === key;
                            const classes = cx(
                              "formatted-text",
                              styles.CandidateCardChatCard,
                              isSender
                                ? styles.CandidateCardChatCardMyMessage
                                : styles.CandidateCardChatCardCandidateMessage,
                            );

                            return (
                              <>
                                <Card key={key} className={classes}>
                                  {msg.message}
                                  <span
                                    className={styles.CandidateCardChatTime}
                                  >
                                    {msg.formattedTime}
                                  </span>
                                  {isSender && isLastItem ? (
                                    <span
                                      className={styles.CandidateCardChatStatus}
                                    >
                                      {AVAILABLE_STATUSES[msg.status]}
                                    </span>
                                  ) : null}
                                </Card>
                              </>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}

                  <div className={cx(styles.CandidateCardChatDate)}>
                    <div className={styles.CandidateCardChatDateValue}>
                      {day.split("|")[1]}
                    </div>
                  </div>
                </>
              );
            })}
            {!Object.keys(messages).length && NO_MESSAGES}
          </div>

          <div className={cx(styles.CandidateCardCreateChatField, "mt-8")}>
            <div
              className={cx(
                styles.CandidateCardCreateChatFieldMessage,
                "mr-12",
              )}
            >
              <TextArea
                className={cx(
                  styles.CandidateCardChatField,
                  isChatFieldHasFocus
                    ? styles.CandidateCardChatFieldFocus
                    : null,
                )}
                disabled={isLoadingMsgSend}
                indent={false}
                onBlur={() => {
                  changeFocusState(false);
                }}
                onChange={(e: any) => setUserMessageText(e.value)}
                onFocus={() => {
                  changeFocusState(true);
                }}
                onKeyDown={(e: any) => {
                  if (e.code === "ShiftLeft") changeShiftPressed(true);
                }}
                onKeyPress={handleKeyPress}
                onKeyUp={(e: any) => {
                  if (e.code === "ShiftLeft") changeShiftPressed(false);
                }}
                placeholder="Введите"
                shape="default"
                value={{
                  value: userMessage,
                }}
              />
            </div>
            <div
              className={cx(
                styles.CandidateCardCreateChatFieldSendBtn,
                "text-right",
              )}
            >
              <Button
                disabled={!userMessage.length || isLoadingMsgSend}
                iconLeft={<GlyphChevronRight />}
                onClick={createRequestAndSendMessage}
                variant="secondary"
              ></Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CardDialog;
