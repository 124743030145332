import { api, request } from "../common";
import {
  API_CANDIDATE_AUTOCOMPLETE,
  API_CANDIDATE_DATA,
  API_CANDIDATE_LIST,
  API_CANDIDATE_DOWNLOAD_RESUME,
  API_CANDIDATE_INFO_RESUME,
  API_CANDIDATE_MOVE_STATUSES,
  API_CANDIDATES_GET_AVAILABLE_STATUSES,
  API_V1,
  API_CANDIDATE_PARSE,
  API_CANDIDATE_STATUS_LIST_ALL,
  API_CANDIDATE_STATUS_HISTORY,
  API_CANDIDATE,
  API_CANDIDATE_FIND_BY_PHONE,
  API_CANDIDATE_BIND_TO_VACANCY,
  API_CANDIDATE_UNBIND_FROM_VACANCY,
  API_PERSON,
  API_CANDIDATE_DELETE_RESUME,
  API_CANDIDATE_FIND_BY_EMAIL,
  API_CANDIDATE_BIND_LABEL,
  API_CANDIDATE_BIND_LABELS,
  API_CANDIDATE_UNBIND_LABELS,
  API_MASS_CANDIDATE_MOVE_STATUSES,
  API_PERSON_STATE_AVAILABLE,
} from "../constants";
import Cookies from "js-cookie";

export const candidateApi = api.injectEndpoints({
  endpoints: builder => ({
    bindLabelToPerson: builder.mutation<any, any>({
      query: params => {
        return {
          method: "POST",
          params: {
            labelId: params.labelId,
          },
          url: `${API_CANDIDATE_BIND_LABEL(params.personId)}`,
          withCredentials: true,
        };
      },
    }),
    bindLabelsToPerson: builder.mutation<any, any>({
      query: data => {
        return {
          data: {
            labelIds: data.labelIds,
          },
          method: "POST",
          url: `${API_CANDIDATE_BIND_LABELS(data.personId)}`,
          withCredentials: true,
        };
      },
    }),
    candidateAutocomplete: builder.query<any, any>({
      query: params => {
        return {
          method: "GET",
          params: {
            query: params.query,
            vacancyId: params.vacancyId || "",
          },
          url: API_CANDIDATE_AUTOCOMPLETE,
          withCredentials: true,
        };
      },
    }),
    candidateCard: builder.query<any, any>({
      query: params => {
        return {
          method: "GET",
          url: `${API_CANDIDATE_DATA(params.candidateId)}`,
          withCredentials: true,
        };
      },
    }),
    candidateChangeStatus: builder.mutation<any, any>({
      query: params => {
        return {
          method: "POST",
          params,
          url: `${API_CANDIDATE_MOVE_STATUSES(
            params.personId,
            params.vacancyId,
          )}`,
          withCredentials: true,
        };
      },
    }),
    candidateCreate: builder.mutation<any, any>({
      query: data => {
        return {
          data,
          method: "POST",
          url: API_PERSON,
          withCredentials: true,
        };
      },
    }),
    candidateDelete: builder.mutation<any, string>({
      query: id => {
        return {
          method: "DELETE",
          url: `${API_CANDIDATE_DATA(id)}`,
          withCredentials: true,
        };
      },
    }),
    candidateDeleteResume: builder.mutation<any, any>({
      query: params => ({
        method: "DELETE",
        url: `${API_CANDIDATE_DELETE_RESUME(params.personId)}`,
        withCredentials: true,
      }),
    }),
    candidateParseFile: builder.mutation<any, any>({
      query: data => ({
        data: data.document,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: API_CANDIDATE_PARSE,
      }),
    }),
    candidateStatusHistory: builder.mutation<any, any>({
      query: params => {
        return {
          method: "GET",
          params,
          url: `${API_CANDIDATE_STATUS_HISTORY(
            params.personId,
            params.vacancyId,
          )}`,
          withCredentials: true,
        };
      },
    }),
    candidateStatuses: builder.query<any, void>({
      query: () => {
        return {
          method: "GET",
          url: `${API_CANDIDATE_STATUS_LIST_ALL}`,
          withCredentials: true,
        };
      },
    }),
    candidatesList: builder.query<any, any>({
      query: params => {
        return {
          method: "GET",
          params,
          url: `${API_CANDIDATE_LIST}`,
          withCredentials: true,
        };
      },
    }),
    downloadResume: builder.query<any, any>({
      query: params => {
        return {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
            "Content-Type": "multipart/form-data",
          },
          method: "GET",
          responseType: "blob",
          url: `${API_CANDIDATE_DOWNLOAD_RESUME(params)}`,
          withCredentials: true,
        };
      },
    }),
    fileInfo: builder.mutation<any, any>({
      query: params => ({
        method: "GET",
        url: `${API_CANDIDATE_INFO_RESUME(params)}`,
        withCredentials: true,
      }),
    }),
    fileInfoResume: builder.query<any, any>({
      query: params => {
        return {
          method: "GET",
          url: `${API_CANDIDATE_INFO_RESUME(params)}`,
          withCredentials: true,
        };
      },
    }),
    findByEmail: builder.mutation<any, any>({
      query: query => ({
        method: "GET",
        params: {
          email: query.email,
        },
        url: API_CANDIDATE_FIND_BY_EMAIL,
        withCredentials: true,
      }),
    }),
    findByPhone: builder.mutation<any, any>({
      query: query => ({
        method: "GET",
        params: {
          phone: query.phone,
        },
        url: API_CANDIDATE_FIND_BY_PHONE,
        withCredentials: true,
      }),
    }),
    getAvailableCandidateStatuses: builder.mutation<any, any>({
      query: query => {
        return {
          method: "GET",
          url: `${API_CANDIDATES_GET_AVAILABLE_STATUSES(
            query.candidateId,
            query.vacancyId,
          )}`,
          withCredentials: true,
        };
      },
    }),
    massCandidateChangeStatus: builder.mutation<any, any>({
      query: data => ({
        data,
        method: "POST",
        url: API_MASS_CANDIDATE_MOVE_STATUSES,
        withCredentials: true,
      }),
    }),
    personBindToVacancy: builder.mutation<any, any>({
      query: params => ({
        method: "POST",
        params: {
          vacancyId: params.vacancyId,
        },
        url: `${API_CANDIDATE_BIND_TO_VACANCY(params.personId)}`,
        withCredentials: true,
      }),
    }),
    personStateTransitionAvailable: builder.mutation<any, any>({
      query: () => ({
        method: "GET",
        url: API_PERSON_STATE_AVAILABLE,
        withCredentials: true,
      }),
    }),
    personUnbindFromVacancy: builder.mutation<any, any>({
      query: params => ({
        method: "POST",
        params: {
          vacancyId: params.vacancyId,
        },
        url: `${API_CANDIDATE_UNBIND_FROM_VACANCY(params.personId)}`,
        withCredentials: true,
      }),
    }),
    unbindLabelsToPerson: builder.mutation<any, any>({
      query: data => {
        return {
          data: {
            labelIds: data.labelIds,
          },
          method: "POST",
          url: `${API_CANDIDATE_UNBIND_LABELS(data.personId)}`,
          withCredentials: true,
        };
      },
    }),
    updateCandidate: builder.mutation<any, any>({
      query: data => ({
        data,
        method: "PUT",
        url: `${API_CANDIDATE(data.id)}`,
        withCredentials: true,
      }),
    }),
    uploadResume: builder.mutation<any, any>({
      query: data => ({
        data: data.document,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: `${API_V1}/person/${data.personId}/file/upload`,
        withCredentials: true,
      }),
    }),
  }),
});

export const candidateCustomApi = {
  downloadResume: async (params: any) => {
    return await request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
      transform: (body: any, response: any, _: any) => {
        return { data: body, headers: response.headers };
      },
      url: `${API_CANDIDATE_DOWNLOAD_RESUME(params)}`,
      withCredentials: true,
    });
  },
};

export const {
  useBindLabelsToPersonMutation,
  useBindLabelToPersonMutation,
  useCandidateAutocompleteQuery,
  useCandidateCardQuery,
  useCandidateChangeStatusMutation,
  useCandidateCreateMutation,
  useCandidateDeleteMutation,
  useCandidateDeleteResumeMutation,
  useCandidateParseFileMutation,
  useCandidatesListQuery,
  useCandidateStatusesQuery,
  useCandidateStatusHistoryMutation,
  useFileInfoMutation,
  useFileInfoResumeQuery,
  useFindByEmailMutation,
  useFindByPhoneMutation,
  useGetAvailableCandidateStatusesMutation,
  useLazyCandidateCardQuery,
  useLazyCandidatesListQuery,
  useMassCandidateChangeStatusMutation,
  usePersonBindToVacancyMutation,
  usePersonStateTransitionAvailableMutation,
  usePersonUnbindFromVacancyMutation,
  useUnbindLabelsToPersonMutation,
  useUpdateCandidateMutation,
  useUploadResumeMutation,
} = candidateApi;
