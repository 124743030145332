import { Skeleton } from "@aurora/components";
import cx from "classnames";

const CreateOrUpdateVacancySkeleton = () => {
  return (
    <>
      {new Array(2).fill(null).map((i, idx) => {
        return (
          <div key={idx}>
            <div className={cx("mt-16", "skeletons-flex-field")}>
              <div>
                <Skeleton
                  height={32}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
              <div>
                <Skeleton
                  height={32}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
            </div>

            <div className={cx("mt-16", "skeletons-flex-field")}>
              <div>
                <Skeleton
                  height={64}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
              <div>
                <Skeleton
                  height={64}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
            </div>

            <div className={cx("mt-16")}>
              <div>
                <Skeleton
                  height={64}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
            </div>
            <div className={cx("mt-16", "skeletons-flex-field")}>
              <div>
                <Skeleton
                  height={32}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
              <div>
                <Skeleton
                  height={32}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
            </div>
            <div className={cx("mt-16")}>
              <div>
                <Skeleton
                  height={48}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
            </div>
            <div className={cx("mt-16", "skeletons-flex-field")}>
              <div>
                <Skeleton
                  height={32}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
              <div>
                <Skeleton
                  height={32}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CreateOrUpdateVacancySkeleton;
