import styles from "./CandidateBadge.module.scss";
import { getCategoryString } from "./helpers";
import { Badge } from "@aurora/components";
import cx from "classnames";

const CandidateBadge: React.FC<Props> = ({ badge }) => {
  if (!badge || !badge.stateCategoryCode) {
    return null;
  }

  return (
    <div title={badge.stateTitle}>
      <Badge
        className={cx(styles.CandidateBadge, "truncate-cell-percent")}
        color={getCategoryString(badge.stateCategoryCode)}
        label={badge.stateTitle}
        theme={"light"}
        variant={"fh-v2"}
      />
    </div>
  );
};

export default CandidateBadge;
