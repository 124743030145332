import { api } from "../common";
import {
  GET_USER_AVAILABLE,
  USER_URL,
  GET_USER_PERMISSIONS,
  GET_USER_CATEGORIES,
} from "../constants";
import { OAuth2TokenResponse } from "types/vacation";

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    createUser: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          data,
          method: "POST",
          url: `${USER_URL}`,
          withCredentials: true,
        };
      },
    }),
    getUserCategories: builder.mutation<any, void>({
      query: (data: any) => {
        return {
          data,
          method: "GET",
          url: `${GET_USER_CATEGORIES}`,
          withCredentials: true,
        };
      },
    }),
    getUserInfo: builder.mutation<OAuth2TokenResponse, void>({
      query: () => ({
        method: "GET",
        url: `${USER_URL}`,
        withCredentials: true,
      }),
    }),
    getUserPermission: builder.mutation<OAuth2TokenResponse, void>({
      query: () => ({
        method: "GET",
        url: `${GET_USER_PERMISSIONS}`,
        withCredentials: true,
      }),
    }),
    isAvailableType: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          data,
          method: "POST",
          url: `${GET_USER_AVAILABLE}`,
          withCredentials: true,
        };
      },
    }),
  }),
});

export const {
  useCreateUserMutation,
  useGetUserCategoriesMutation,
  useGetUserInfoMutation,
  useGetUserPermissionMutation,
  useIsAvailableTypeMutation,
} = userApi;
