import styles from "./RightSidebar.module.scss";
import { GlyphClose } from "@aurora/icons";
import React, { FC, ReactNode, useRef, useState } from "react";

interface Props {
  children: ReactNode;
  onClose: () => void;
  show: boolean;
}

const RightSidebar: FC<Props> = ({ children, onClose, show }) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isClosing, changeClose] = useState(false);
  return (
    <div
      className={`${styles.RightSidebarWrapper} ${
        show && !isClosing ? styles.show : ""
      }`}
    >
      <div
        className={styles.LeftSideWrapper}
        onClick={() => {
          changeClose(true);
          setTimeout(() => {
            onClose();
            changeClose(false);
          }, 300);
        }}
      >
        <div
          className={`${styles.LeftSideWrapperClose} ${
            show && !isClosing ? styles.LeftSideWrapperCloseOpen : ""
          }`}
          title="Закрыть"
        >
          <GlyphClose />
        </div>

        <div id="RightSideBarFilterBlock"></div>
      </div>

      <div ref={sidebarRef} className={styles.RightSidebar}>
        <div className={styles.RightSidebarChildren}>{children}</div>
      </div>
    </div>
  );
};

export default RightSidebar;
