import FilterList from "./FilterList/FilterList";
import List from "./List/List";
import style from "./Vacancies.module.scss";
import { QueryParameters } from "./types";
import { Button } from "@aurora/components";
import { GlyphPlus } from "@aurora/icons";
import cx from "classnames";
import { ROUTE_VACANCIES_CREATE } from "constants/routes";
import { useVacanciesListQuery } from "core/api/vacancy/vacancy";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Vacancies: FC = () => {
  const navigate = useNavigate();
  const isManagerOrWatcher = useSelector(
    (store: any) => store.userDataSlice.isManagerOrWatcher,
  );
  const [queryParams, setQueryParams] = useState<QueryParameters>({
    activeOnly: true,
    categoryIds: [],
    createPeriodEnd: "",
    createPeriodStart: "",
    delayedOnly: false,
    managerIds: [],
    pageNum: 1,
    pageSize: 10,
    stateCodes: [],
    vacancyTitle: [],
    vacancyTitles: [],
  });

  const {
    data: vacanciesData,
    error: vacanciesError,
    isLoading: isVacanciesLoading,
  } = useVacanciesListQuery(queryParams, {
    refetchOnMountOrArgChange: true,
  });
  // отображение ошибки в консоле
  useEffect(() => {
    if (vacanciesError) console.error("vacanciesError", vacanciesError);
  }, [vacanciesError]);

  const pageCount = vacanciesData
    ? Math.ceil(vacanciesData?.total / queryParams.pageSize)
    : 0;

  return (
    <>
      <div className={style.Vacancies}>
        <div className={style.VacanciesList}>
          <h1 className={cx("my-0 text-black circle-btn-height")}>Вакансии</h1>
          {!isManagerOrWatcher && (
            <Button
              className={cx(style.AddVacancionButton, "p-6", "px-12")}
              icon={<GlyphPlus />}
              onClick={() => {
                navigate(ROUTE_VACANCIES_CREATE);
              }}
              shape="circle"
              variant="primary"
            ></Button>
          )}
        </div>

        <div className={cx(style.VacationList)}>
          <div className={style.VacationListLeftSide}>
            <List
              isVacanciesLoading={isVacanciesLoading}
              pageCount={pageCount}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              vacanciesData={vacanciesData}
            ></List>
          </div>
          <div className={style.VacationListRightSide}>
            <FilterList setQueryParams={setQueryParams}></FilterList>
          </div>
        </div>
      </div>
    </>
  );
};
export default Vacancies;
