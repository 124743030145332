import { Skeleton } from "@aurora/components";
import cx from "classnames";

const SkeletonField = () => {
  return (
    <>
      <div>
        <div className={cx("skeletons-flex-field")}>
          <div>
            <Skeleton height={32} radius="r5" shape="line" variant="primary" />
          </div>
          <div>
            <Skeleton height={32} radius="r5" shape="line" variant="primary" />
          </div>
        </div>

        <div className={cx("mt-8", "skeletons-flex-field")}>
          <div>
            <Skeleton height={32} radius="r5" shape="line" variant="primary" />
          </div>
          <div>
            <Skeleton height={32} radius="r5" shape="line" variant="primary" />
          </div>
        </div>

        <div className={cx("mt-8")}>
          <div>
            <Skeleton height={32} radius="r5" shape="line" variant="primary" />
          </div>
        </div>
        <div className={cx("mt-8", "skeletons-flex-field")}>
          <div>
            <Skeleton height={32} radius="r5" shape="line" variant="primary" />
          </div>
          <div>
            <Skeleton height={32} radius="r5" shape="line" variant="primary" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SkeletonField;
