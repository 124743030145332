import {
  useCandidateAutocompleteQuery,
  useCandidateStatusesQuery,
} from "../../../../core/api/candidate/candidate";
import {
  setCandidates,
  setDatePickerValue,
  setStatusData,
} from "../../../../core/store/reducers/FilterSelectedSlice";
import { formatISODate } from "../../../../services/date.service";
import { SkeletonOneLine } from "../../VacationCard/Skeleton/SkeletonField";
import style from "./FilterBlock.module.scss";
import {
  InputDatepicker,
  Multiselect,
  MultiselectData,
} from "@aurora/components";
import { GlyphSearch } from "@aurora/icons";
import cx from "classnames";
import { store } from "core/store";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface Props {
  updateCandidateData: any;
  vacancyId?: string;
}

const FilterBlock: FC<Props> = ({ updateCandidateData, vacancyId }: Props) => {
  const {
    candidatesSelector: multiselectData,
    datepickerValue,
    statusData: statusIdsData,
  } = useSelector((store: any) => store.filterSelectedSlice);
  const [autocompleteCandidate, changeAutocompleteCandidate] = useState("");
  const [needClear, setNeedClear] = useState(false);
  const [isFirstLoad, changeFirstLoad] = useState(true);

  const {
    data: candidateAutocompleteData,
    error: candidateAutocompleteError,
    isLoading: candidateAutocompleteLoading,
  } = useCandidateAutocompleteQuery(
    { query: autocompleteCandidate, vacancyId: vacancyId },
    {
      skip: autocompleteCandidate.length < 3,
    },
  );

  const {
    data: candidateStatusAll,
    error: candidateStatusAllError,
    isLoading: isLoadingCandidateStatusAll,
  } = useCandidateStatusesQuery();

  useEffect(() => {
    if (candidateStatusAllError)
      console.error("candidateStatusAllError", candidateStatusAllError);
    if (candidateAutocompleteError)
      console.error("candidateAutocompleteError", candidateAutocompleteError);
  }, [candidateAutocompleteError, candidateStatusAllError]);

  const handleSubmit = () => {
    updateCandidateData((oldParams: any) => {
      return {
        ...oldParams,
        pageNum: 1,
        personIds: multiselectData.map((person: MultiselectData) => person.id),
        resumeUploadPeriodEnd: formatISODate(datepickerValue[1]),
        resumeUploadPeriodStart: formatISODate(datepickerValue[0]),
        stateCodes: statusIdsData.map((status: any) => status.code),
      };
    });
  };

  useEffect(() => {
    if (!needClear) return;
    setNeedClear(false);
  }, [needClear, statusIdsData]);

  useEffect(() => {
    if (isFirstLoad) {
      changeFirstLoad(false);
      return;
    }
    // Любые изменения в фильрах
    handleSubmit();
  }, [multiselectData, statusIdsData, datepickerValue]);

  return (
    <>
      {isLoadingCandidateStatusAll ? (
        <SkeletonOneLine />
      ) : (
        <div className={cx(style.CandidatesFilterBlock, "mb-20")}>
          <div className={style.CandidatesFilterBlockField}>
            <Multiselect
              addonBefore={<GlyphSearch />}
              className="mb-0"
              data={candidateAutocompleteData || []}
              indent={false}
              loading={candidateAutocompleteLoading}
              onChange={(e: any) => store.dispatch(setCandidates(e))}
              onInputChange={changeAutocompleteCandidate}
              placeholder="Фамилия кандидата"
              shape="select"
              showArrow={false}
              value={multiselectData}
            />
          </div>
          <div className={style.CandidatesFilterBlockField}>
            <Multiselect
              data={candidateStatusAll}
              onChange={(e: any) => store.dispatch(setStatusData(e))}
              placeholder="Статус"
              value={statusIdsData}
            />
          </div>
          <div className={style.CandidatesFilterBlockField}>
            <InputDatepicker
              classNameBackgroundColor={undefined}
              dates={datepickerValue}
              disabled={false}
              onBlurContainerField={undefined}
              onFocusContainerField={undefined}
              onSubmit={dates => store.dispatch(setDatePickerValue(dates))}
              placeholder={"Дата резюме"}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default FilterBlock;
