import styles from "./Auth.module.scss";
import { IAuthError } from "./types";
import { Button, Input, InputPassword } from "@aurora/components";
import cx from "classnames";
import { ICONS } from "components/BaseComponents/Icons";
import { usePostLoginMutation } from "core/api/oauth2";
import { store } from "core/store";
import { setAuth, setLoading } from "core/store/reducers/AuthSlice";
import { tabWithEnter } from "hooks/tabWithEnter";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Auth: FC = () => {
  const navigate = useNavigate();
  const [username, changeUsername] = useState("");
  const [password, changePassword] = useState("");

  const [
    auth,
    { data: loginData, error: loginError, isLoading: isLoginLoading },
  ] = usePostLoginMutation();
  // отображение ошибки в консоли
  useEffect(() => {
    if (loginError) console.error("loginError", loginError);
  }, [loginError]);

  const isDisabled = !(username && password) || isLoginLoading;

  const [authError, setAuthError] = useState<IAuthError>({
    description: "",
    status: "success",
  });

  const hiddenError = () => {
    setAuthError({
      description: "",
      status: "success",
    });
    return tabWithEnter;
  };

  const handleSubmit = async (e?: any) => {
    e?.preventDefault();

    try {
      const authRes: any = await auth({
        password,
        username,
      });

      if (authRes.error) {
        setAuthError({
          description: authRes.error.data.message
            ? authRes.error.data.message
            : "Сервис временно недоступен",
          status: "error",
        });
      } else {
        store.dispatch(setAuth(true));
        navigate("/");
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      store.dispatch(setLoading(false));
    }
  };

  return (
    <div className={styles.authContainer}>
      <h1 className={styles.authLogo}>{ICONS.LOGO}</h1>
      <div className={styles.section}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            label={"Логин"}
            onChange={(e: any) => {
              changeUsername(e.target.value);
            }}
            onKeyDown={tabWithEnter}
            placeholder={"Введите логин"}
            value={username}
          />
          <InputPassword
            description={authError.description}
            label={"Пароль"}
            onChange={(e: any) => {
              changePassword(e.target.value);
            }}
            onKeyDown={hiddenError}
            placeholder={"Введите пароль"}
            status={authError.status}
            value={password}
          />
          <div className={cx(styles.buttonSection, "mt-8")}>
            <Button
              className={cx(styles.buttonLogin)}
              disabled={isDisabled}
              type="submit"
              variant="primary"
            >
              Войти
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Auth;
