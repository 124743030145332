import { StatusData } from "../../../types/main";
import { MultiselectData } from "@aurora/components";
import { createSlice } from "@reduxjs/toolkit";

interface IFilterSelectedSlice {
  candidatesSelector: MultiselectData[];
}
interface IFilterStatusData {
  statusData: StatusData[];
}
interface IDatePickerValue {
  datepickerValue: Date[];
}

const initialState: IFilterSelectedSlice &
  IFilterStatusData &
  IDatePickerValue = {
  candidatesSelector: [],
  datepickerValue: [],
  statusData: [],
};

export const filterSelectedSlice = createSlice({
  initialState,
  name: "filterSelected",
  reducers: {
    resetToInitState: (state, _) => {
      state.candidatesSelector = [];
      state.datepickerValue = [];
      state.statusData = [];
    },
    setCandidates: (state, action) => {
      state.candidatesSelector = action.payload;
    },
    setDatePickerValue: (state, action) => {
      state.datepickerValue = action.payload;
    },
    setStatusData: (state, action) => {
      state.statusData = action.payload;
    },
  },
});

export const {
  resetToInitState,
  setCandidates,
  setDatePickerValue,
  setStatusData,
} = filterSelectedSlice.actions;
export default filterSelectedSlice.reducer;
