import style from "./Modal.module.scss";
import { Button, Popup } from "@aurora/components";
import cx from "classnames";
import { ICONS } from "components/BaseComponents/Icons";
import { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  clickOnClose: any;
  phone: string;
}

const ConnectWithSocialNetwork: FC<Props> = ({ clickOnClose, phone }) => {
  return (
    <>
      <Popup
        classNameContent={cx(style.modal, "p-16")}
        classNameWrapper="p-0"
        onClickCloseCross={clickOnClose}
        onClickOutside={clickOnClose}
      >
        <>
          <div className={"title-and-buttons-align"}>
            <h1 className={cx("my-0 text-accent")}>Связаться с кандидатом</h1>
            <div>
              <Button onClick={clickOnClose} variant="secondary">
                Отмена
              </Button>
            </div>
          </div>
          <div>
            <p>
              Вы продолжите общение с кандидатом с личного аккаунта, поэтому не
              забудьте представиться
            </p>

            <div className="text-center">
              <Link
                target="_blank"
                to={`//api.whatsapp.com/send?phone=${phone.replace(
                  /[^\d]/g,
                  "",
                )}`}
              >
                {ICONS.WHATS_APP_ICON}
              </Link>
            </div>
          </div>
        </>
      </Popup>
    </>
  );
};
export default ConnectWithSocialNetwork;
