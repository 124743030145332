import { GlyphAlert, GlyphCheck, GlyphClose } from "@aurora/icons";

export const snackbarFail = function (caption: string) {
  return {
    autoClose: false,
    caption,
    icon: (
      <div className="alert-snackbar alert-snackbar-fail">
        <GlyphClose />
      </div>
    ),
    showCloseButton: true,
    variant: (["primary", "secondary"] as const)[0],
  };
};

export const snackbarSuccess = function (caption: string) {
  return {
    autoClose: 5000,
    caption,
    icon: (
      <div className="alert-snackbar alert-snackbar-success">
        <GlyphCheck />
      </div>
    ),
    showCloseButton: true,
    variant: (["primary", "secondary"] as const)[0],
  };
};

export const snackbarForbidden = function (caption: string) {
  return {
    autoClose: false,
    caption,
    icon: (
      <div className="alert-snackbar alert-snackbar-warning">
        <GlyphAlert />
      </div>
    ),
    showCloseButton: true,
    variant: (["primary", "secondary"] as const)[0],
  };
};
