import { ROUTE_EDIT_CANDIDATE } from "../../../../constants/routes";
import { useCandidateCardQuery } from "../../../../core/api/candidate/candidate";
import SkeletonField from "../../VacationCard/Skeleton/SkeletonField";
import style from "./Card.module.scss";
import CardData from "./CardData";
import CardDialog from "./CardDialog";
import CardHistory from "./CardHistory";
import CardVacancies from "./CardVacancies";
import { Tabs, Tab, Indicator } from "@aurora/components";
import { GlyphEdit, GlyphMore, GlyphPhone, GlyphSurvey } from "@aurora/icons";
import cx from "classnames";
import AdditionallyMenu from "components/BaseComponents/AdditionallyMenu";
import ConnectWithSocialNetwork from "components/BaseComponents/Popups/ConnectWithSocialNetwork/Modal";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { generateRandomIdByLength } from "services/generate.service";

interface ICardProps {
  candidate?: any;
  openUpdateStatus?: any;
  openBindCandidateModal?: any;
  onCloseBindCandidateModal?: any;
}

const tabs = {
  0: "Данные",
  1: "История",
  2: "Вакансии",
  3: "Диалог",
};

const Card: FC<ICardProps> = ({
  candidate,
  onCloseBindCandidateModal,
  openBindCandidateModal,
}: any) => {
  const isManagerOrWatcher = useSelector(
    (store: any) => store.userDataSlice.isManagerOrWatcher,
  );
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, changeActiveTab] = useState(0);
  const [isCardLoading, changeLoading] = useState(true);
  const [talkInSocialNetwork, changeTalkInSocialNetwork] = useState(false);
  const [isMessagesRead, changeReadStatus]: any = useState(false);

  const [queryParams, setQueryParams] = useState({
    candidateId: candidate?.id,
    rnd: "",
  });

  const {
    data: candidateData,
    error: candidateError,
    isLoading: candidateLoading,
  } = useCandidateCardQuery(queryParams, {
    refetchOnMountOrArgChange: true,
    skip: !queryParams.candidateId,
  });

  useEffect(() => {
    if (candidateError) console.error("candidateError", candidateError);
  }, [candidateError]);

  useEffect(() => {
    changeLoading(false);
    setSearchParams({ candidateId: queryParams.candidateId });
  }, []);

  const newMessageTemplate = () => {
    return (
      <span className={cx("mr-4")}>
        <Indicator shape="point" variant="blueActive" />
      </span>
    );
  };

  const menuList = [
    {
      icon: <GlyphEdit />,
      name: "Редактировать",
      onClick: () => {
        navigate(`${ROUTE_EDIT_CANDIDATE}/${candidateData?.id}`);
      },
    },
    {
      icon: <GlyphSurvey />,
      name: "Связи с вакансиями",
      onClick: () => {
        openBindCandidateModal(true);
        onCloseBindCandidateModal(() => {
          return () => {
            setQueryParams({
              candidateId: candidate?.id,
              rnd: generateRandomIdByLength(10),
            });
          };
        });
      },
    },
    {
      hide: !candidate.phone,
      icon: <GlyphPhone />,
      name: "Связаться в мессенджерах",
      onClick: () => {
        changeTalkInSocialNetwork(true);
      },
    },
  ];

  return (
    <>
      {isCardLoading || candidateLoading || !candidateData ? (
        <SkeletonField count={8} />
      ) : (
        <>
          <div className={style.CandidateCardHeader}>
            <div>
              <h1 className="my-0 text-black mt-4">
                {candidateData?.fullName}
              </h1>
            </div>
            {!isManagerOrWatcher && (
              <div className={style.CandidateCardHeaderButton}>
                <AdditionallyMenu
                  btn={{
                    className: cx(
                      style.CreateButton,
                      "text-black",
                      "bg-content-2",
                    ),
                    icon: <GlyphMore />,
                    variant: "secondary",
                  }}
                  list={menuList}
                />
              </div>
            )}
          </div>
          <div>
            {isManagerOrWatcher ? (
              <Tabs
                className="mb-8"
                onChange={(v, value) => {
                  if (value === 3) {
                    changeReadStatus(true);
                  }
                  changeActiveTab(value);
                }}
                value={activeTab}
                variant={"primary"}
              >
                <Tab label={tabs[0]} value={0}></Tab>
              </Tabs>
            ) : (
              <Tabs
                className="mb-8"
                onChange={(v, value) => {
                  if (value === 3) {
                    changeReadStatus(true);
                  }
                  changeActiveTab(value);
                }}
                value={activeTab}
                variant={"primary"}
              >
                <Tab label={tabs[0]} value={0}></Tab>
                <Tab label={tabs[1]} value={1}></Tab>
                <Tab label={tabs[2]} value={2}></Tab>
                <Tab
                  iconRight={
                    candidate?.hasNewMessages && !isMessagesRead
                      ? newMessageTemplate()
                      : null
                  }
                  label={tabs[3]}
                  value={3}
                ></Tab>
              </Tabs>
            )}
          </div>
          {activeTab === 0 && <CardData candidate={candidateData} />}
          {activeTab === 1 && (
            <CardHistory
              personId={candidateData?.id}
              vacancyIds={candidateData.candidates.map(
                (candidate: any) => candidate.vacancyId,
              )}
            />
          )}
          {activeTab === 2 && (
            <CardVacancies
              candidate={candidateData}
              updateCandidateData={setQueryParams}
              vacancyIds={candidateData?.candidates.map(
                (item: any) => item.vacancyId,
              )}
            />
          )}
          {activeTab === 3 && (
            <CardDialog
              personId={candidateData?.id}
              phone={candidateData?.phone}
              vacancyIds={candidateData?.candidates.map(
                (item: any) => item.vacancyId,
              )}
            ></CardDialog>
          )}
          {talkInSocialNetwork ? (
            <ConnectWithSocialNetwork
              clickOnClose={() => {
                changeTalkInSocialNetwork(false);
              }}
              phone={candidate.phone}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default Card;
