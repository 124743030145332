export const ROUTE_ROOT = "/";

export const ROUTE_MAIN = "/vacancies";
export const ROUTE_VACANCIES = "/vacancies";
export const ROUTE_VACANCIES_CREATE = ROUTE_VACANCIES + "/create";
export const ROUTE_VACANCIES_UPDATE = ROUTE_VACANCIES + "/edit";

export const ROUTE_CANDIDATE = "/candidate";
export const ROUTE_STATISTIC = "/statistic";

export const ROUTE_EDIT_CANDIDATE = ROUTE_CANDIDATE + "/edit";
export const ROUTE_CREATE_CANDIDATE = ROUTE_CANDIDATE + "/create";

export const ROUTE_SETTINGS = "/settings";
export const ROUTE_SETTINGS_CREATE = ROUTE_SETTINGS + "/create";

export const ROUTE_AUTH = "/auth";
export const ROUTE_LOGOUT = "/logout";
