// /api/v1 / dictionary / city / all
import { api } from "../common";
import {
  API_CREATE_CHAT,
  API_GET_EXTERNAL_CHAT_LIST,
  API_GET_ALL_MESSAGES,
  API_SEND_MESSAGE,
  API_CHAT_HAS_ANY_NEW_MESSAGES,
} from "../constants";

export const baseApi = api.injectEndpoints({
  endpoints: builder => ({
    addChat: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          data,
          method: "POST",
          url: `${API_CREATE_CHAT}`,
          withCredentials: true,
        };
      },
    }),
    getExternalChatList: builder.mutation<any, any>({
      query: (params: any) => {
        return {
          method: "GET",
          params,
          url: `${API_GET_EXTERNAL_CHAT_LIST}`,
          withCredentials: true,
        };
      },
    }),
    getMessages: builder.mutation<any, any>({
      query: (chatId: string) => {
        return {
          method: "GET",
          url: `${API_GET_ALL_MESSAGES(chatId)}`,
          withCredentials: true,
        };
      },
    }),
    hasAnyNewMessages: builder.mutation<any, void>({
      query: () => ({
        method: "GET",
        url: `${API_CHAT_HAS_ANY_NEW_MESSAGES}`,
        withCredentials: true,
      }),
    }),
    sendMessage: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          data,
          method: "POST",
          url: `${API_SEND_MESSAGE}`,
          withCredentials: true,
        };
      },
    }),
  }),
});

export const {
  useAddChatMutation,
  useGetExternalChatListMutation,
  useGetMessagesMutation,
  useHasAnyNewMessagesMutation,
  useSendMessageMutation,
} = baseApi;
