import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import style from "./AdditionallyMenu.module.scss";
import { ActionCell, Button, CardCell } from "@aurora/components";
import cx from "classnames";
import { FC, useEffect, useState } from "react";

interface IDataItem {
  onClick?: any;
  name?: string;
  icon?: any;
  variant?: string;
  className?: string;
  hide?: boolean;
  shape?: string;
}
interface Props {
  list?: IDataItem[];
  btn: IDataItem;
}

const AdditionallyMenu: FC<Props> = ({ btn, list }) => {
  const [menuVisible, setMenuVisible]: any = useState(false);
  const [timeoutId, setTimeoutId]: any = useState(null);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [ref, isVisible] = useIntersectionObserver({
    threshold: 0.1, // Настройте по вашим требованиям
  });

  useEffect(() => {
    if (ref.current) {
      // Здесь можно выполнить нужные действия
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const menuHeight = ref.current.offsetHeight;
      const menuWidth = ref.current.offsetWidth;
      // Проверка, выходит ли меню за границы окна и корректировка позиции
      const position = { ...menuPosition };
      if (position.y + menuHeight > windowHeight) {
        position.y -= menuHeight;
      }
      if (position.x + menuWidth > windowWidth) {
        position.x -= menuWidth;
      }
      setMenuPosition({ ...position });
    }
  }, [isVisible]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuVisible]);

  const btnVariant: any = btn.variant || "primary";
  const btnShape: any = btn.shape || "default";

  const hideMenu = () => {
    setMenuVisible(false);
  };
  const handleContextMenu = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const tdRect =
      event.target.closest("td")?.getBoundingClientRect() ??
      event.target.closest("div")?.getBoundingClientRect();
    setMenuPosition({
      x: event.clientX - 250 + tdRect.width - tdRect.left,
      y: tdRect.top,
    });
    if (btn.onClick) btn.onClick();
    setMenuVisible(true);
  };
  const handleMouseLeave = () => {
    setTimeoutId(setTimeout(hideMenu, 500));
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
  };

  const handleClickOutside = (event: any) => {
    if (menuVisible && !event.target.closest("#additionalContextMenu")) {
      setMenuVisible(false);
    }
  };

  return (
    <div className={cx(style.AdditionallyMenuContainer)}>
      <Button
        className={cx(
          style.AdditionallyMenuButton,
          btn.className,
          "p-6",
          btnShape === "circle" ? style.AdditionallyMenuButtonCircle : "",
        )}
        icon={btnShape === "circle" ? btn.icon : null}
        iconLeft={btnShape === "circle" ? null : btn.icon}
        onClick={handleContextMenu}
        shape={btnShape}
        variant={btnVariant}
      >
        {btn.name}
      </Button>
      {menuVisible ? (
        <div
          ref={ref}
          className={cx(style.AdditionallyMenu)}
          id="additionalContextMenu"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            left: `${menuPosition.x}px`,
            top: `${menuPosition.y}px`,
          }}
        >
          <CardCell indent={false}>
            <div className={cx(style.AdditionallyMenuList)}>
              {list?.map((dataItem, idx) => {
                return dataItem.hide ? null : (
                  <div key={idx}>
                    <ActionCell
                      className={cx(
                        "cursor-pointer",
                        dataItem.className,
                        "px-12",
                        "py-6",
                      )}
                      iconLeft={dataItem.icon}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        dataItem.onClick();
                      }}
                      separator={idx < list.length - 1}
                      title={dataItem.name}
                    />
                  </div>
                );
              })}
            </div>
          </CardCell>
        </div>
      ) : null}
    </div>
  );
};

export default AdditionallyMenu;
