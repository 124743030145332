import Candidates from "../Candidates";
import CreateOrUpdateVacancy from "../CreateOrUpdateVacancy";
import EditCandidate from "../EditCandidate";
import Logout from "../Logout";
import Settings from "../Settings";
import CreateOrUpdateEmployee from "../Settings/CreateOrUpdateEmployee";
import Statistics from "../Statistics";
import Vacancies from "../Vacancies";
import style from "./Content.module.scss";
import {
  AvatarItem,
  Sidebar,
  SidebarMenu,
  useSnackbars,
} from "@aurora/components";
import {
  GlyphAnalytics,
  GlyphBetweenAccounts,
  GlyphPeople,
  GlyphSettings,
} from "@aurora/icons";
import cx from "classnames";
import Error404 from "components/BaseComponents/404/404";
import { ICONS } from "components/BaseComponents/Icons";
import {
  ROUTE_LOGOUT,
  ROUTE_VACANCIES,
  ROUTE_VACANCIES_CREATE,
  ROUTE_CANDIDATE,
  ROUTE_STATISTIC,
  ROUTE_VACANCIES_UPDATE,
  ROUTE_EDIT_CANDIDATE,
  ROUTE_CREATE_CANDIDATE,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_CREATE,
} from "constants/routes";
import { useGetUserInfoMutation } from "core/api/user/user";
import { store } from "core/store";
import {
  isCatetgoryManagerOrWatcher,
  setUser,
} from "core/store/reducers/UserDataSlice";
import { FC, memo, useEffect, useState } from "react";
import { Navigate, Route, Routes, NavLink } from "react-router-dom";

/**
 * ContentBlock - блок контента, отвечающий за роутинг и отображение
 * компонентов на странице.
 *
 */

const Content: FC = () => {
  const { snackbarInfo, SnackbarsInfoContainer, snackbarTopAlert } =
    useSnackbars();

  const [userData, changeUserData]: any = useState({});
  const [sidebarLinks, changeSidebarLinks]: any = useState([]);

  const [loadUserData] = useGetUserInfoMutation();
  useEffect(() => {
    const links = [
      {
        icon: <GlyphBetweenAccounts />,
        name: "Вакансии",
        route: ROUTE_VACANCIES,
      },
      {
        icon: <GlyphPeople />,
        name: "Кандидаты",
        route: ROUTE_CANDIDATE,
      },
    ];

    loadUserData().then(({ data }: any) => {
      store.dispatch(setUser(data));
      const isManagerOrWatcher =
        data.category === "MANAGER" || data.category === "WATCHER";
      if (data.category) {
        store.dispatch(isCatetgoryManagerOrWatcher(isManagerOrWatcher));
      }
      changeUserData(data);

      if (!isManagerOrWatcher) {
        changeSidebarLinks(() => {
          return [
            ...links,
            {
              icon: <GlyphAnalytics />,
              name: "Статистика",
              route: ROUTE_STATISTIC,
            },
            {
              icon: <GlyphSettings />,
              name: "Настройки",
              route: ROUTE_SETTINGS,
            },
          ];
        });
      } else {
        changeSidebarLinks(() => {
          return [...links];
        });
      }
    });
  }, []);

  return (
    <>
      <div className={style.Content}>
        <div className={style.ContentSidebar}>
          <div className={cx(style.ContentSidebarTop, "pl-8")}>
            <div className={cx(style.ContentSidebarTopLogo, "py-12 mb-8")}>
              {ICONS.LOGO}
            </div>
            <div className={cx(style.ContentSidebarTopUserBlock, "mb-8")}>
              <AvatarItem
                caption={userData.fullName}
                className="mb-2"
                description={userData.category}
              />
            </div>
          </div>

          <Sidebar className={"mb-8"}>
            <SidebarMenu
              className={style.ContentSidebarMenu}
              variant="transparent"
            >
              {sidebarLinks.map((link: any, idx: number) => {
                return (
                  <div key={idx}>
                    <NavLink
                      className={({ isActive }) => {
                        return isActive
                          ? style.ContentSidebarMenuLinkActive
                          : style.ContentSidebarMenuLink;
                      }}
                      to={link.route}
                    >
                      <div className={style.ContentSidebarManuItem}>
                        <div
                          className={cx(
                            "mr-6",
                            style.ContentSidebarManuItemIcon,
                          )}
                        >
                          {link.icon}
                        </div>
                        <div>{link.name}</div>
                      </div>
                      {/* <MenuItem
                        className={style.ContentSidebarManuItem}
                        direction="vertical"
                        iconLeft={link.icon}
                        label={link.name}
                        shape="tab"
                      /> */}
                    </NavLink>
                  </div>
                );
              })}
            </SidebarMenu>
          </Sidebar>
          <div className={style.ContentSidebarBottom}>
            <NavLink
              className={cx(style.ContentSidebarBottomLogout, "py-8 pl-8")}
              to={ROUTE_LOGOUT}
            >
              <svg
                fill="none"
                height="25"
                viewBox="0 0 24 25"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.90002 8.36175C9.21002 4.76175 11.06 3.29175 15.11 3.29175H15.24C19.71 3.29175 21.5 5.08175 21.5 9.55175V16.0717C21.5 20.5417 19.71 22.3317 15.24 22.3317H15.11C11.09 22.3317 9.24002 20.8817 8.91002 17.3417"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
                <path
                  d="M15 12.8018H3.62"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
                <path
                  d="M5.85 9.45178L2.5 12.8018L5.85 16.1518"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </svg>

              <span className="ml-8">Выйти</span>
            </NavLink>
          </div>
        </div>
        <div className={style.ContentMain} id={"contentMain"}>
          <div
            className={style.ContentMainContainer}
            id={"contentMainContainer"}
          >
            <Routes>
              <Route element={<Navigate to={ROUTE_VACANCIES} />} path="/" />
              <Route element={<Vacancies />} path={ROUTE_VACANCIES} />
              <Route element={<Candidates />} path={ROUTE_CANDIDATE} />
              <Route
                element={<CreateOrUpdateVacancy />}
                path={`${ROUTE_VACANCIES_CREATE}`}
              />
              <Route
                element={
                  <CreateOrUpdateVacancy isEdit={true}></CreateOrUpdateVacancy>
                }
                path={`${ROUTE_VACANCIES_UPDATE}/:vacancyId`}
              />
              <Route
                element={<EditCandidate />}
                path={`${ROUTE_EDIT_CANDIDATE}/:personId`}
              />
              <Route
                element={<EditCandidate />}
                path={`${ROUTE_CREATE_CANDIDATE}`}
              />
              <Route element={<Statistics />} path={`${ROUTE_STATISTIC}`} />
              <Route element={<Settings />} path={`${ROUTE_SETTINGS}`} />
              <Route
                element={<CreateOrUpdateEmployee />}
                path={`${ROUTE_SETTINGS_CREATE}`}
              />

              <Route element={<Logout />} path={`${ROUTE_LOGOUT}`} />

              <Route element={<Error404 />} path="*" />
            </Routes>
          </div>
        </div>
      </div>
      <div className={style.AlertContainer}>
        <SnackbarsInfoContainer />
      </div>
    </>
  );
};

export default memo(Content);
