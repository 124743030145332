import { BadgeColor } from "@aurora/components";

const categoryMap: { [key in IBadge["stateCategoryCode"]]: BadgeColor } = {
  CLOSED: "success",
  CV_DECLINED: "danger",
  CV_REQUESTER: "other2",
  FINAL: "intense",
  HIRED: "success",
  HOLD: "foreground",
  HR_DECLINED: "danger",
  INTERNAL_APPROVED: "other",
  INTERNAL_REFUSE: "danger",
  INTERVIEW_1: "other2",
  INTERVIEW_FINAL: "success",
  INTERVIEW_INTERNAL: "default",
  MISSED_CALL: "alert",
  NEW: "start",
  OFFER_DECLINED: "danger",
  OTHER: "foreground",
  PLANED_TO_HIRE: "success",
  REQUESTER_APPROVED: "other2",
  REQUESTER_DECLINED: "danger",
  SECURITY: "info",
  SECURITY_DECLINED: "danger",
  SELFREJECTION: "danger",
  WORK: "default",
};

export function getCategoryString(
  stateCategoryCode: IBadge["stateCategoryCode"],
): BadgeColor {
  return categoryMap[stateCategoryCode];
}
