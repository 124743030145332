import {
  candidateCustomApi,
  useFileInfoResumeQuery,
} from "../../../../core/api/candidate/candidate";
import CopyText from "../../../BaseComponents/CopyText";
import Label from "../../../BaseComponents/Label";
import style from "./Card.module.scss";
import {
  InformationCell,
  MultiselectData,
  Skeleton,
  Text,
  UploadedFile,
} from "@aurora/components";
import { getFormattedFileSize } from "@aurora/components/dist/FileUploader/helpers";
import { ICONS } from "components/BaseComponents/Icons";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface ICardProps {
  candidate: any;
}

const CardData: FC<ICardProps> = ({ candidate }) => {
  const isManagerOrWatcher = useSelector(
    (store: any) => store.userDataSlice.isManagerOrWatcher,
  );
  const [fileResume, setFileResume]: any = useState<File | null>();
  const cardDataLabels = [
    {
      addonRight: <CopyText textToCopy={candidate?.phone} />,
      icons: [
        {
          available: !!candidate.phone,
          icon: isManagerOrWatcher ? null : (
            <Link
              target="_blank"
              to={`//api.whatsapp.com/send?phone=${candidate.phone.replace(
                /[^\d]/g,
                "",
              )}`}
            >
              {ICONS.WHATS_APP_ICON}
            </Link>
          ),
        },
      ],
      label: "Телефон",
      value: candidate?.phone,
    },
    {
      label: "Файл резюме",
      value: "",
    },
    {
      addonRight: <CopyText textToCopy={candidate?.email} />,
      label: "E-mail",
      value: candidate?.email,
    },
    {
      label: "Дата загрузки резюме",
      value: candidate?.resumeUploaded
        ? new Date(candidate?.resumeUploadDateTime).toLocaleDateString()
        : "-",
    },
  ];
  const [labels, setLabels] = useState<MultiselectData[]>([]);

  const {
    data: infoResume,
    error: infoResumeError,
    isLoading: infoResumeLoading,
  } = useFileInfoResumeQuery(candidate?.id, {
    skip: !candidate?.resumeUploaded,
  });

  useEffect(() => {
    if (infoResumeError) console.error("infoResumeError", infoResumeError);
  }, [infoResumeError]);

  useEffect(() => {
    if (candidate?.resumeUploaded && infoResume !== undefined) {
      candidateCustomApi.downloadResume(candidate?.id).then((file: any) => {
        const res = new File([file.data], infoResume.fileName);
        setFileResume(res);
      });
    }
  }, [candidate, infoResume]);

  useEffect(() => {
    if (candidate.labels.length > 0) {
      const labelsMap: MultiselectData[] = [...candidate.labels];
      setLabels(labelsMap);
    }
  }, [candidate]);

  return (
    <div className={"border-bottom pb-16"}>
      <div className={style.CandidateCardData}>
        {cardDataLabels.map((item, idx) => (
          <div key={idx + item.label}>
            <InformationCell
              addonRight={item.addonRight ?? null}
              label={
                <Text tag={"div"} type="labelRegular">
                  {item.label}
                </Text>
              }
              separator={false}
              title={item.value || "-"}
            />
            <div className={style.CandidateCardDataIcons}>
              {item.icons?.map((icon, iconIdx) => {
                if (!icon.available) return null;
                return <div key={idx + item.label + iconIdx}>{icon.icon}</div>;
              })}
            </div>
          </div>
        ))}
      </div>
      <Label
        entity={"person"}
        initLabels={labels}
        needConfirmed={true}
        needSaveBt={true}
        onLabelsChange={setLabels}
        personId={candidate.id}
      />
      {candidate?.resumeUploaded && (
        <div className={"pt-16 border-top"}>
          <InformationCell
            label={
              <Text tag={"div"} type={"labelRegular"}>
                Загруженные файлы
              </Text>
            }
            separator={false}
            title={""}
          />
          <a
            className={"text-decoration-none"}
            onClick={() => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(fileResume);
              link.download = infoResume.fileName;
              link.dispatchEvent(new MouseEvent("click"));
            }}
          >
            {fileResume && !infoResumeLoading ? (
              <UploadedFile
                description={getFormattedFileSize(infoResume.contentSize)}
                errorDescription={"errorDescription"}
                file={fileResume}
                iconSize={"medium"}
                indent={false}
                loadingDescription={"loading"}
                shape={"cardCell"}
                shortDescription={"short"}
                showDeleteButton={false}
                status={"FILE_SUCCESS"}
                title={infoResume.fileName}
              />
            ) : (
              <div>
                <Skeleton
                  height={64}
                  radius="r5"
                  shape="line"
                  variant="primary"
                />
              </div>
            )}
          </a>
        </div>
      )}
    </div>
  );
};

export default CardData;
