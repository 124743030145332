import { MultiselectData } from "@aurora/components";

export const diffMultiselectData = (
  arr1: MultiselectData[],
  arr2: MultiselectData[],
): MultiselectData[] => {
  const result = arr1.filter(
    item1 =>
      !arr2.some(item2 => item2.value === item1.value && item2.id === item1.id),
  );
  if (result?.length > 0) return result;
  return [];
};
