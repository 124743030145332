import { Skeleton } from "@aurora/components";
import { FC } from "react";

interface ISkeletonTableProps {
  rows: number;
}

export const SkeletonTable: FC<ISkeletonTableProps> = ({ rows }): any => {
  return (
    <>
      {new Array(rows).fill(null).map((it: any, idx: number) => {
        return (
          <tr key={idx}>
            <td>
              <Skeleton
                height={32}
                radius="r4"
                shape="line"
                variant="secondary"
              />
            </td>
            <td>
              <Skeleton
                height={32}
                radius="r4"
                shape="line"
                variant="secondary"
              />
            </td>
            <td>
              <Skeleton
                height={32}
                radius="r4"
                shape="line"
                variant="secondary"
              />
            </td>
            <td>
              <Skeleton
                height={32}
                radius="r4"
                shape="line"
                variant="secondary"
              />
            </td>
            <td>
              <Skeleton
                height={32}
                radius="r4"
                shape="line"
                variant="secondary"
              />
            </td>
            <td>
              <Skeleton
                height={32}
                radius="r4"
                shape="line"
                variant="secondary"
              />
            </td>
          </tr>
        );
      })}
    </>
  );
};
