import { Input, Select } from "@aurora/components";
import { FC, useState } from "react";

interface Props {
  statusFrom?: any;
  statusTo?: any;
}
const Status: FC<Props> = ({ statusFrom, statusTo }) => {
  const [selectedForm, changeSelectedFrom]: any = useState(null);
  const [selectedTo, changeSelectedTo]: any = useState(null);
  return (
    <>
      <Select
        className={"py-6 px-8"}
        data={statusFrom || []}
        label="Выберите статус из которого перевести"
        onChange={changeSelectedFrom}
        placeholder="Выберите из списка"
        shape="autocomplite"
        value={selectedForm}
      />
      <Select
        className={"py-6 px-8"}
        data={statusTo || []}
        label="Выберите статус перевода"
        onChange={changeSelectedTo}
        placeholder="Выберите из списка"
        shape="autocomplite"
        value={selectedTo}
      />
      <p className={"w-40"}>
        Вы можете написать комментарий для перевода статуса
      </p>
      <Input className={"py-6 px-8"} placeholder="Введите сообщение" />
    </>
  );
};

export default Status;
