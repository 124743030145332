import { Button } from "@aurora/components";
import { GlyphCheck, GlyphCopy } from "@aurora/icons";
import React, { FC, useState } from "react";

interface Props {
  textToCopy: string;
}
const CopyText: FC<Props> = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      })
      .catch(err => {
        console.error("Failed to copy text: ", err);
      });
  };
  return (
    <>
      <Button
        icon={isCopied ? <GlyphCheck color={"green"} /> : <GlyphCopy />}
        onClick={copyToClipboard}
        shape="action"
      />
    </>
  );
};

export default CopyText;
